import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import firebase from "firebase";
import { games } from "./dummy";

// Customizable Area Start
import { endUserReportViewSVG, rowNoSelectReportSVG } from "./assets";
import React from 'react'
import { Button } from "@material-ui/core";
interface GameData {
  id: string;
  gameName: string;
  competition: string;
  gameLength: string; // or number if you prefer
  gameDate: string; // or Date type if you need date manipulation
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currentPage: number;
  rowsPerPage: number;
  totalPages: number; 
  initialLoading:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AnalyticsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess)
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      currentPage: 1,        // Current page
      rowsPerPage: 10, // Items per page
      totalPages: 1,  // Total pages from backend
      initialLoading: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
    if (firebase.apps.length !== 0) {
      const defaultAnalytics = firebase.app().analytics();
      defaultAnalytics.logEvent("Analytics::Web::Load");
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
  }
  tableCell(){
    return (
      <img src={rowNoSelectReportSVG} />
    )
  }
  viewAnalyticsDetail = () =>{
    this.props.navigation.navigate('AnalyticsDetail')
  }
   getColumns() {
    return  [
      { columnName: "", keyExtractor: "id" , render:() => this.tableCell() },
      { columnName: "Game Name", keyExtractor: "gameName" },
      { columnName: "Compitation", keyExtractor: "competition" },
      { columnName: "Game Length", keyExtractor: "gameLength" },
      { columnName: "Game Date", keyExtractor: "gameDate" },
      { columnName: "", keyExtractor: "", render:() =>(
        <Button
        data-test-id='view-btn'
          variant="outlined"
          style={{
            color: "#FF881A",
            borderColor: "#FF881A",
            borderRadius: 8,
            padding: "10px 16px 10px 16px",
            fontFamily: "Montserrat",
            fontWeight: 600,
            lineHeight: "normal",
            width: "120px",
            height: '40px'
          }}
          onClick={() => this.viewAnalyticsDetail()}
          
        >
          <div style={{display:'flex', gap:'8px', justifyContent: 'center', alignContent:'center',alignItems:'center',textAlign:'center'}}> View <img src={endUserReportViewSVG}/></div>
        </Button>
      ) },

    ];
  }
  getUserData(): {[key: string]: string | null}[]{
    return games
  }

  handleChangePage = ( event: React.ChangeEvent<unknown>,newPage:number) => {
    this.setState({ currentPage: newPage }, () => {
      // search function
    });
  };
  handleSearchName = (event:any) =>{

  }
  handleOnSearch = () =>{

  }
  // Customizable Area End
}
