import { Box } from '@material-ui/core';
import * as React from 'react';
import CustomUiProgress from './CustomUiProgress.web';

export interface IFile {
  "fileName": string;
  "duration": number;
  "progress": number;
  "timeMarkSecond": number;
  "isOptimization": boolean;
  "inputFilePath": string;
  "masterFiles": string;
  "folderPath"?: string;
  "attachmentId"?: string;
  "size"?: number;
  "sizeUploaded"?: number;
}

interface IProcessFile {
  videoFilePath: string;
  message: string;
  progress: number;
  isOptimization?: boolean;
}

export default function OptimizationStatus() {
  const [process, setProcess] = React.useState<IProcessFile>({ videoFilePath: "", message: "", progress: 0, isOptimization: false })
  const [listFile, setListFile] = React.useState<IFile[]>([])
  const [isEnableOptimization, setIsEnableOptimization] = React.useState(false)

  React.useEffect(() => {
    const initializationListFile = async () => {
      if (window?.api) {
        const dataStore = await window?.api?.getListFileProcess();
        if (dataStore.length) {
          setListFile(dataStore)
          for (const data of dataStore) {
            await window?.api?.resumeOptimization(data);
          }
        }
      }
    }
    initializationListFile();

    window?.api?.receive('select-video-file', (data: any) => {
      setListFile(data)
    });

    window?.api?.receive('is-enable-optimization', (data: any) => {
      setIsEnableOptimization(data)
    });

    return () => {
      window?.api?.removeAllListeners('select-video-file');
      window?.api?.removeAllListeners('is-enable-optimization');
    };
  }, [])

  React.useEffect(() => {
    window?.api?.receive('video-processing-progress', (data: any) => {
      setProcess(data);
    });
    return () => {
      window?.api?.removeAllListeners('video-processing-progress');
    };
  })

  React.useEffect(() => {
    const mappingListFile = listFile.map(file => {
      if (file.inputFilePath === process.videoFilePath) {
        if (!!process?.isOptimization) return {...file, progress: 100, isOptimization: true}
        return { ...file, progress: Math.round(process.progress) }
      }
      return { ...file }
    })
    setListFile(mappingListFile)
  }, [process])

  const isCheckHasOptimizedFile = listFile.some(file => file.progress <= 100 && !file.isOptimization)
  return (
    <Box>
      {
        isEnableOptimization && <CustomUiProgress isOpenSnackbar= {isCheckHasOptimizedFile} listFile={listFile}/>
      }
    </Box>
  );
}

