export const filter = require("../assets/filter.png");
export const filterOnHover = require('../assets/filter_on_hover.png')
export const search = require("../assets/search.png");
export const endUserReportSVG = require("../assets/end_user_report.svg")
export const teamLogo = require("../assets/team_logo.png")
export const radialLogo = require("../assets/user_report_stat.svg")
export const statBarLogo = require("../assets/user_report_bar.svg")
export const rowReportSVG = require("../assets/end_user_row_report_view.svg")
export const rowNoSelectReportSVG = require("../assets/end_user_no_select_report.svg")
export const endUserReportViewSVG = require("../assets/end_user_report_view.png")

