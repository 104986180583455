import React from 'react';
import Chart from 'react-apexcharts';

const RadialBarChart = () => {
    const options = {

        series: [44, 55, 67],
        options: {
            chart: {
                height: 300,
                type: 'radialBar',
            },
            plotOptions: {
                radialBar: {
                    dataLabels: {
                        name: {
                            fontSize: '22px',
                        },
                        value: {
                            fontSize: '8px',
                        },
                        total: {
                            show: true,
                            label: 'Total',
                            formatter: function (w: any) {
                                // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                                return 249
                            }
                        }
                    }
                }
            },
            labels: ['Apples', 'Oranges', 'Bananas',],
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: function (value: any) {
                        return value + '%'; // Customize tooltip value display
                    }
                }
            }
        },
    }


    return (
        <div>
            <Chart options={options} series={options.series} type="radialBar" height={350} />
        </div>
    );
};

export default RadialBarChart;
