Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.jsonApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfcustomkeyboards";
exports.labelBodyText = "cfcustomkeyboards Body";

exports.getAllKBGroupsEndPoint = "bx_block_settings/keyboards/show_all_groups";
exports.createKBGroupEndPoint = "bx_block_settings/keyboards/create_group";
exports.getCompetitionEndPoint = "bx_block_settings/compitition_compatibilities";

exports.getDefaultActionsEndPoint = "account_block/sport_titles/default_actions";
exports.getActionsBankEndPoint = "account_block/sport_titles/show_action_bank";
exports.getSubActionsEndPoint = "bx_block_settings/sub_actions";
exports.getSubActionsBankEndPoint = "account_block/sport_titles/updated_show_sub_action";
exports.getValuesEndPoint = "bx_block_settings/values/fetch_values_by_default_action";
exports.getValuesBankEndPoint = "bx_block_settings/values/value_bank";
exports.getReasonsEndPoint = "bx_block_settings/reasons/fetch_reasons_by_default_action";
exports.getReasonsBankEndPoint = "bx_block_settings/sub_reason_bank";
exports.getSubReasonsEndPoint = "bx_block_settings/sub_reasons/fetch_sub_reasons_by_default_action";
exports.getSubReasonsBankEndPoint = "bx_block_settings/sub_reason_bank";

exports.saveActionsEndPoint = "account_block/sport_titles/update_action_bank";
exports.saveSubActionsEndPoint = "account_block/sport_titles/update_action_sub_actions";
exports.saveValuesEndPoint = "bx_block_settings/values/assign_values_to_actions";
exports.saveReasonsEndPoint = "bx_block_settings/assign_reasons";
exports.saveSubReasonsEndPoint = "bx_block_settings/assign_sub_reasons";
exports.saveCommentEndPoint = "account_block/keyboard/:keyboard_id/update_comment";
exports.savePart2DataEndPoint = "bx_block_settings/keyboards/assign_data";

exports.getMethod = "GET";
exports.putMethod = "PUT";
exports.postMethod = "POST";
exports.deleteMethod = "DELETE";
exports.btnExampleTitle = "CLICK ME";

exports.getDefaultKeyboardsURL = "bx_block_settings/default_keyboards";
exports.getMethod = "GET";

exports.renameKeyboardGroupURL = "bx_block_settings/keyboards/keyboardGroupId/rename_group";
exports.patchMethod = "PATCH";
exports.emptyKbGrName = "Keyboard Group Name cannot be empty!";
exports.createKbGrSuccess = "Create Keyboard Group Successfully!";
exports.createKbGrFail = "Create Keyboard Group Failed!";
exports.emptyCustomKeyboardName = "Custom Keyboard Name cannot be empty!";
exports.createCustomKeyboardURL = "bx_block_settings/keyboards";
exports.createCustomKeyboardSuccess = "Create Custom Keyboard Successfully!";
exports.createCustomKeyboardFail = "Create Custom Keyboard Failed!";
exports.getDueDateEndpoint="bx_block_custom_user_subs/get_due_date"
exports.getRolesAndPositionsURL = 'bx_block_settings/keyboards/staffs_and_positions';

exports.activateCustomKeyboardURL = "bx_block_settings/keyboards/keyboardId/toggle_active";
exports.successMessage = "Success!";
exports.failMessage = "Failed!";
exports.getTeamURL = "bx_block_settings/teams";
exports.saveTeamURL = "bx_block_settings/keyboards/keyboardId/add_teams";
exports.putMethod = "PUT";
exports.assignTeamSuccess = "Assign Team Successfully!";
exports.assignTeamFail = "Assign Team Failed!";
exports.saveRolePositionURL = "bx_block_settings/keyboards/keyboardId/add_staffs_and_positions";
exports.getKeyboardInfoURL = "bx_block_settings/keyboards/keyboardId";
exports.saveCompetitionCompatibilityURL = "bx_block_settings/keyboards/keyboardId/add_compitition_compatibilities"
exports.saveCompetitionFail = "Add Competition Compatibilities Failed!";
exports.deleteCustomKeyboardUrl = "bx_block_settings/keyboards/keyboardId/delete_keyboard";
exports.getPlayersURL = "bx_block_settings/staff_and_players";
exports.savePlayerURL = "bx_block_settings/keyboards/keyboardId/add_staffs_and_players"
exports.maxKeyboardNumberReached = "Max Number of Keyboard Reached!";
exports.duplicateCustomKeyboardURL = "bx_block_settings/keyboards/keyboardId/create_duplicate_keyboard";
exports.maxKeyboardNameLengthExceeded = "Keyboard name cannot exceed 20 characters. Please enter a shorter name!"
// Customizable Area End