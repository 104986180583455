import React from "react";

// Customizable Area Start
import {
  Typography,
  Box,
  styled,
  Button,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableCell,
  TableBody,
  Modal,
  Input,
  Menu,
  MenuItem,
  Dialog,
  IconButton,
  Divider,
} from "@material-ui/core";
import { Close, CloseOutlined } from "@material-ui/icons";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { 
  markVideoIcon, 
  layoutVideoIcon,
  filterIcon,
  back,
  back15s,
  back5s,
  next,
  next15s,
  next5s,
  blankAvatar,
  commentIcon,
  editIcon,
  deleteButton,
  starIconButton,
  reportIcon,
  stickerComment,
  listIcon,
} from "./assets";
import CustomVideoPlayer from "../../../components/src/CustomVideoPlayer";
import Events, { getColorForValue } from "../../../components/src/CustomKeyboards/Events.web";
import Loader from "../../../components/src/Loader.web";
import ImageComponent from "../../../components/src/ImageComponent.web";
import { 
  backIcon
 } from "../../../../packages/blocks/customisableusersubscriptions/src/assets";
import BrowseRecentGame from "../../../components/src/BrowseRecentGame.web";
import VideoEventController, { 
  Props
 } from "./VideoEventController";
import ModalStatusMarking from "../../../components/src/Modal/ModalStatusMarking.web";
import CommentContent from "./components/CommentContent.web";
// Customizable Area End


export default class VideoEvent extends VideoEventController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  columns = [
    { id: 'photo', label: '', flex: 1 },
    { id: 'id', label: '#', flex: 1 },
    { id: 'default_action_id', label: 'ACTION', flex: 2 },
    { id: 'sub_action_id', label: 'SUB ACTION', flex: 2 },
    { id: 'value_id', label: 'VALUE', flex: 2 },
    { id: 'reason_id', label: 'REASON', flex: 2 },
    { id: 'sub_reason_id', label: 'SUB REASON', flex: 2 },
    { id: 'event_duration', label: 'TIME', flex: 2 },
    { id: 'options', label: 'OPTIONS', flex: 4 },
  ];

  renderTopMenu() {
    return(
      <TopMenuStyled>
        <Box data-test-id="top-menu-back-btn" className="top-menu-back-btn" onClick={this.handleGoBack}>
          <img src={backIcon} width={10} />
        </Box>

        <Button className="top-menu-item second-item">
          <Typography className="top-menu-label" style={{ color: "#FF881A" }}>Game: </Typography>
          <Typography style={{marginLeft: 8}} className="top-menu-label">{this.state.videoDetail?.team_name} Vs {this.state.videoDetail?.oppo_team_name}</Typography>
        </Button>
        <Box style={{ flex: 1 }} />
        {!this.state.finished ? (<Button className="top-menu-item" data-test-id="btn_finish_marking" onClick={() => this.handleFinishMarking()}>
          <Typography className="top-menu-label">Finish Marking</Typography>
          <img src={markVideoIcon} className="menu-icon" />
        </Button>) : <Button className="top-menu-item" style={{border: "1px solid #FF881A"}}>
          <Typography className="top-menu-label">Go to Report</Typography>
          <img src={reportIcon} className="menu-icon" />
        </Button>}
        <Box style={{ width: 24 }}/>
        <Button data-test-id="layout-btn" className="top-menu-item" onClick={() => this.onChangeLayout()}>
          <Typography className="top-menu-label">Layout</Typography>
          <img src={this.state.isGridView ? listIcon : layoutVideoIcon} className="menu-icon" /> 
        </Button>
      </TopMenuStyled>
    )
  }

  renderPlayerPOV() {
    return (
      <div className="listUser_teamVideo">
        {this.state.listUserForAddEvent.map((item, index) => (
          <Box className="user_teamVideo" style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 10,
            marginLeft: 10,
          }}
            key={index}
          >
            <Typography className="user_teamVideo-name">{item?.attributes?.fullname}</Typography>
            <ImageComponent alt="i" fallbackSrc={blankAvatar} widthImage="80px" heightImage="80px" style={{borderRadius: 10, overflow: "hidden"}} src={item.attributes?.photo || blankAvatar}/>
          </Box>
        ))}
      </div>
    )
  }

  renderTableHeader() {
    return (
      <TableHead>
        <TableRow>
          {this.columns.map((column) => (
            <TableCell
              className="tableCell"
              key={column.id}
              align={"center"}
              style={{ flex: column.flex, fontSize: "10px", fontWeight: 600, color: "#676767", textAlign: "center", padding: 0 }}
            >
              {column.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
  }

  renderEventTable() {

    return (
      <EventTableStyled>
        <Box className="header"
          data-test-id="event-item-container"
        >
          <Box>
            <Typography style={{ color: "#CFD1D4", fontSize: 20 }}>Events</Typography>
            <Typography style={{ color: "#676767" }}>{this.getHeaderEvent()}</Typography>
          </Box>
          <Box style={{display: "flex", columnGap: "8px"}}>
            <Button className="btnHeader" >
              <Typography style={{ marginRight: 10, color: "#CFD1D4" }}>Filter</Typography>
              <img style={{ width: 20, height: 20 }} src={filterIcon} />
            </Button>
          </Box>
        </Box>
        <TableContainer className="tableContent">
          <Table>
            {this.renderTableHeader()}
            <TableBody>
              {this.state.events.map((rowItem: any) => {
                interface Column {
                  id: string,
                  flex: number
                }
                return (
                  <TableRow className="tableRow" hover role="checkbox" tabIndex={-1} key={rowItem.id}>
                    {this.columns.map((column: Column) => {
                      const value = rowItem.attributes[column.id] || rowItem.attributes[column.id]?.name || "-";
                      const eventId = rowItem?.id;
                      const isHasComment = Boolean(rowItem?.attributes?.comment) || rowItem.attributes.has_commented;
                      const isBookmark = Boolean(rowItem?.attributes?.current_user_bookmarked);
                      const valueKeyboard = rowItem?.attributes?.value_id?.name;
                      return (
                        <TableCell className="tableCell" key={column.id} style={{ flex: column.flex, fontSize: "12px", fontWeight: 500, padding: 0, color: "#CFD1D4", textAlign: "center" }}>
                          {column.id === "photo" ? (
                            <ImageComponent
                              style={{ borderLeft: `4px solid ${getColorForValue(valueKeyboard)}`, borderTopLeftRadius: "4px", borderBottomLeftRadius: "4px" }}
                              fallbackSrc={blankAvatar}
                              alt="i"
                              widthImage="30px"
                              heightImage="30px"
                              src={(value === "-" || !value) ? blankAvatar : value} />
                          ) : undefined}
                          {column.id === "options" ? (
                            <Box style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}>
                              <Box data-test-id="show-comment-btn" onClick={() => this.toggleCommentModal(rowItem.id) } style={{ cursor: "pointer" }}>
                                <ImageComponent widthImage="18px" heightImage="18px" alt="i" colorSvgLine={isHasComment ? "#FF881A" : ""} fallbackSrc={commentIcon} src={commentIcon} style={{ width: 16, height: 16 }}/>
                              </Box>
                              <Box>
                                <img src={editIcon} style={{ width: 18, height: 18 }}/>
                              </Box>
                              <Box data-test-id="delete-event-btn" onClick={()=>this.handleOpenDeleteModal(rowItem.id)} style={{ cursor: "pointer" }}>
                                <img src={deleteButton} style={{ width: 18, height: 18 }}/>
                              </Box>
                              <Box style={{cursor: "pointer"}} data-test-id={isBookmark ? "isActiveBookmark" : "isDeActivateBookmark"} onClick={() => this.handleActiveBookmarkIcon(eventId, isBookmark)}>
                                <ImageComponent widthImage="18px" heightImage="18px" alt="i" colorSvgLine={isBookmark ? "#FF881A" : ""} fallbackSrc={starIconButton} src={starIconButton}/>
                              </Box>
                            </Box>
                          ) : undefined}
                          {column.id !== "photo" && column.id !== "options" && (value?.name || value)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>          
        </TableContainer>
      </EventTableStyled>
    )
  }

  renderVideoArea = () => {
    return (
      <VideoAreaStyled>
        <div className="video-player" data-test-id="video-player">
          {!!this.state.videoUrl ? (<CustomVideoPlayer
            buttonAddEventComponent={this.renderButtonAddEvent()}
            controllerVideoPlayer={this.renderVideoControl}
            ref={this.videoRef}
            clipDuratonBefore={this.state.clipDuratonBefore}
            clipDuratonAfter={this.state.clipDuratonAfter}
            videoUrl={this.state.videoUrl}
            markers={this.state.events.map(element => ({
              id: element.id,
              time: this.convertTimeStringToNumber(element.attributes.event_duration) || 0,
              action: element.attributes.default_action_id?.name,
              subAction: element.attributes.sub_action_id?.name,
              value: element.attributes.value_id?.name,
              reason: element.attributes.reason_id?.name,
              subReason: element.attributes.sub_reason_id?.name,
              photo: element.attributes?.photo
            }))}
            speed={this.state.currentVideoSpeed}
          />) : (
            <div style={{ flex: 1, height: window.innerWidth * 0.31, backgroundColor: "black" }} />
          )}
          {!this.state.isGridView && this.renderAddEventIsNotGrid()}
          {!this.state.isGridView && this.renderVideoControllerNotGrid()}
        </div>
        {this.state.isGridView && this.renderRightEventTable()}
        {!this.state.isGridView && this.renderSidePOV()}
      </VideoAreaStyled>
    )
  }

  renderSidePOV() {
    return (
      <SidePOVStyled className="sidePov">
        {!!this.state.listUserForAddEvent.length && <Typography className="title_text">Team Videos:</Typography>}
        {this.renderPlayerPOV()}
      </SidePOVStyled>
    )
  }

  renderBottomPOV() {
    return (
      <BottomPOVStyled className="botPov">
        {
          !!this.state.listUserForAddEvent?.length &&
          <Typography className="title_text" style={{ marginRight: 10, marginTop: 25 }}>Team Videos:</Typography>
        }
        
        {this.renderPlayerPOV()}
      </BottomPOVStyled>
    )
  }

  renderBottomEventTable() {
    return (
      <BottomEventTableStyled className="bottom_event_table">
        {this.renderEventTable()}
      </BottomEventTableStyled>
    )
  }

  renderRightEventTable() {
    return (
      <RightEventTableStyled data-test-id="right-event-table">
        {this.renderEventTable()}
      </RightEventTableStyled>
    )
  }

  renderVideoControl = (isModeFullScreen: boolean = false) => {
    const isFullScreen = this.videoRef.current.isVideoFullScreen();
    const { videoSpeedMenuAnchor } = this.state;
    const shouldOpenMenu = Boolean(videoSpeedMenuAnchor) && (
      (isFullScreen && isModeFullScreen) || (!isFullScreen && !isModeFullScreen)
    );    
    return <Box className="controller-wrapper">
      <Button data-test-id="back15s-btn" className="video-controll-btn" onClick={() => this.videoRef.current.back15s()}>
        <img src={back15s} className="video-controll-icon"/>
      </Button>
      <Button data-test-id="back5s-btn" className="video-controll-btn" onClick={() => this.videoRef.current.back5s()}>
        <img src={back5s} className="video-controll-icon"/>
      </Button>
      <Button data-test-id="back-btn" className="video-controll-btn" onClick={() => this.videoRef.current.back()}>
        <img src={back} className="video-controll-icon"/>
      </Button>
      <Button
        data-test-id="change-speed-btn"
        className="video-controll-btn"
        onClick={this.onToggleSpeedMenu.bind(this)}
      >
        <Typography>{this.state.currentVideoSpeed}X</Typography>
        <Menu
          open={shouldOpenMenu}
          anchorEl={this.state.videoSpeedMenuAnchor}
          onClose={() => this.setState({ videoSpeedMenuAnchor: null })}
        >
          {this.speedList.map(speedElm => (
            <MenuItem
              key={speedElm}
              data-test-id="change-speed-btn-item"
              onClick={() => this.onHideSpeedMenu(speedElm)}
              style={{
                backgroundColor: speedElm === this.state.currentVideoSpeed ? "rgba(0,0,0,0.1)" : undefined
              }}>{speedElm}X</MenuItem>
          ))}
        </Menu>
      </Button>
      <Button data-test-id="next-btn" className="video-controll-btn" onClick={() => this.videoRef.current.next()}>
        <img src={next} className="video-controll-icon"/>
      </Button>
      <Button data-test-id="next5s-btn" className="video-controll-btn" onClick={() => this.videoRef.current.next5s()}>
        <img src={next5s} className="video-controll-icon"/>
      </Button>
      <Button data-test-id="next15s-btn" className="video-controll-btn" onClick={() => this.videoRef.current.next15s()}>
        <img src={next15s} className="video-controll-icon"/>
      </Button>
    </Box>
  }

  renderButtonAddEvent = () => !this.state.isMarkFinished
    ? (<Box className="function_event">
      <Button className="add-event-btn"
        data-test-id="add-event-btn"
        onClick={() => {
          this.showAddEventModal();
        }}
      >
        <Typography>{"Add Event   +"}</Typography>
      </Button>
    </Box>)
    : (<Box className="function_event">
      <Button className="add-event-btn"
        data-test-id="previous-event-btn"
        onClick={() => {
          this.videoRef.current.previousMarker();
        }}
      >
        <Typography>{"Previous Event   +"}</Typography>
      </Button>
      <Button className="add-event-btn"
        data-test-id="next-event-btn"
        onClick={() => {
          this.videoRef.current.nextMarker();
        }}
      >
        <Typography>{"Next Event   +"}</Typography>
      </Button>
    </Box>)
  renderVideoController() {
    return (
      <VideoControllerStyled>
        {this.renderVideoControl(false)}
        {this.renderButtonAddEvent()}
      </VideoControllerStyled>
    )
  }

  renderAddEventIsNotGrid() {
    return (
      <Box id="add_event-isNotGrid">
        {this.renderButtonAddEvent()}
      </Box>
    )
  }

  renderVideoControllerNotGrid() {
    return (
      <Box id="video_controller-isNotGrid">
        {this.renderVideoControl(false)}
      </Box>
    )
  }

  renderAddEVentModal() {
    const {
      showModalAddEvent,
    } = this.state;
    return (
      <DialogStyled 
        data-test-id="dialogAddEvent"
        open={showModalAddEvent}
        fullWidth={true}
        disableScrollLock
        maxWidth={false}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0)',
          }
        }}
        PaperProps={{
          style: {
            boxShadow: this.getShadowColorDialog(),
            boxSizing: "border-box",
            maxHeight: "420px",
          }
        }}
      >
        <IconButton
          id="closeBtn"
          aria-label="close"
          onClick={() => this.closeModalAddEvent()}
        >
          <CloseOutlined />
        </IconButton>
        <Box className="add_event">
          <Events 
            data-test-id="list_event"
            listValueKeyboard={{listAction: this.state.listAction,  listSubAction: this.state.listSubAction, listValue: this.state.listValue, listReason: this.state.listReason, listSubReason: this.state.listSubReason, commentInput: this.state.commentInput }} 
            handleSelectAction={this.handleSelectAction}
            handleSelectSubAction={this.handleSelectSubAction}
            handleSelectValue={this.handleSelectValue}
            handleSelectReason={this.handleSelectReason}
            handleSelectSubReason={this.handleSelectSubReason}
            handleChangeComment={this.onChangeCommentInput}
            isSubmit={this.state.isCanSubmitEvent}
          />
          <Button disabled={!this.state.isCanSubmitEvent} data-test-id="submit-event-modal-btn" className="confirm-btn" onClick={this.submitAddEvent.bind(this)}>Submit</Button>
        </Box>
      </DialogStyled>
    )
  }

  renderCommentModal() {
    const {
      showCommentModal
    } = this.state;
    return (
      <Modal
        open={showCommentModal}
      >
        <CommentModal>
          <Box className="container modal-comment">
            <Button data-test-id="hide-comment-btn" className="close-btn" onClick={this.hideCommentModal.bind(this)}>
              <Close style={{ color: 'white' }}/>
            </Button>
            <Typography className="modal-label">Comments</Typography>
            <Box className="comment-container">
              {
                this.state.comments.map(comment => {
                  // Because full name and last name always require so I 
                  // will equal commentAuthor be "Me"
                  return (
                    <CommentContent
                      positionProfile={`${comment.commentAuthor === "Me" ? "right" : "left"}`}
                      nameProfile={comment.commentAuthor}
                      roleProfile={comment.commentAuthorRole}
                      contentComment={comment.commentContent}
                      photoProfile={comment.commentAuthorPhoto}
                      commentDate={comment.commentDate}
                    />
                  )
                })
              }
            </Box>
            {!this.state.comments && <Box className="no-comment-text">No comment</Box>}
            <Box className="input-row">
              <Box className="sticker-input">
                <img src={stickerComment} alt="i"/>
              </Box>
              <Input data-test-id="comemnt-input" className="input" placeholder="Reply..." value={this.state.commentInput} onChange={this.onChangeCommentInput.bind(this)}/>
              <Button data-test-id="submit-comment-btn" className="send-btn" onClick={this.onClickSendComment.bind(this)}>Send</Button>
            </Box>
          </Box>
        </CommentModal>
      </Modal>
    )
  }

  renderDeleteModal = () => {
    return (<Dialog
      open={this.state.deleteModal}
      PaperProps={{
        style: {
          border: "unset",
          margin: 'auto',
          background: "transparent",
          maxWidth: "unset"
        },
      }}
      BackdropProps={{
        style: {
          backdropFilter: "blur(5px)", // Adjust the pixel value to increase or decrease the blur effect
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: Adjust the background color and opacity
        }
      }}
    >
      <StyledFolderModal style={{ width: '780px' }}>
        <IconButton
          data-test-id="on-close-delete-modal"
          style={{ cursor: "pointer" }}
          onClick={this.closeDeleteModal}
          aria-label="close">
          <Close style={{ color: "#676767" }} />
        </IconButton>

        <Typography variant="h6">Delete Event!</Typography>

        <Typography variant="body1" style={{ marginTop: '24px' }}>
          Are you sure, you want to delete this Event ?
        </Typography>

        <Divider style={{ width: '496px', margin: "24px auto", backgroundColor: '#FF881A', height: 2 }} />

        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Button
            data-test-id="on-close-delete-modal"
            variant="outlined"
            style={{
              borderRadius: 8,
              color: "#FF881A",
              borderColor: "#FF881A",
              fontFamily: "Montserrat",
              fontSize: 18,
              width: "200px",
              padding: "10px 16px 10px 16px",
              gap: 8,
              fontWeight: 600,
              textTransform: 'none',
              lineHeight: "22px",
              height: '55px',
              cursor: "pointer",
            }}
            onClick={this.closeDeleteModal}
          >
            Cancel
          </Button>
          <Button
            data-testid="delete-event"
            variant="contained"
            style={{
              backgroundColor: "#FF1919",
              color: "#1D1D1F",
              borderRadius: 8,
              fontSize: 18,
              padding: "10px",
              gap: 8,
              fontFamily: "Montserrat",
              fontWeight: 600,
              lineHeight: "22px",
              width: "200px",
              height: '55px',
              marginLeft: '24px',
              cursor: "pointer",
              textTransform: 'none'
            }}
            onClick={()=>this.handleClickDelete()}
          >
            Delete
          </Button>
        </div>
      </StyledFolderModal>
    </Dialog>)
  }

  
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <VideoEventStyled>
        <NavigationMenu navigation={this.props.navigation} id="NavigationMenu" currentTabProp="video" />
        <Box data-test-id="container" className={this.state.isGridView ? "" : "container-isNotGrid"} style={{ marginLeft: 40, marginRight: 40, marginBottom: 100 }}>
          {this.renderDeleteModal()}
          {this.renderAddEVentModal()}
          {this.renderCommentModal()}
          {this.renderTopMenu()}
          {this.renderVideoArea()}
          <Box className="bottomContent">
            <Box className="bottomContent-action">
              {this.state.isGridView && this.renderVideoController()}
              {this.state.isGridView && this.renderBottomPOV()}
              {!this.state.isGridView && this.renderBottomEventTable()}
            </Box>
            <Box className="recentGame">
              <Typography style={{fontFamily: "Poppins", fontSize: "24px", color: "#CFD1D4"}}>
                Browse Recent Games
              </Typography>
              <BrowseRecentGame navigation={this.props.navigation} data={this.state.gameFolders}/>
            </Box>
          </Box>
        </Box>
        <Loader loading={this.state.isLoading} />
        <ModalStatusMarking statusMarking={this.state.statusMarking} isOpen={this.state.isOpenModal} handleCloseModal={this.handleCloseModal}/>
      </VideoEventStyled>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const DialogStyled = styled(Dialog)(({ theme }) => ({
  position: "relative",
  "& #closeBtn": {
    position: "absolute",
    right: "4px",
    color: "#474A4D"
  },
  "& .MuiDialog-scrollPaper": {
    alignItems: "flex-end"
  },
  "& .MuiDialog-paper": {
    margin: 0,
    width: '100%',
    maxWidth: 'none',
    borderRadius: "10px 10px 0 0 ",
    borderTop: "1px solid #0A0A0A",
  },
  "& .add_event": {
    paddingRight: 24,
    display: "flex",
    alignItems: "center",
    background: "#252527",
    "& #events_component": {
      border: 0,
      minHeight: "350px"
    },
    "& .confirm-btn": {
      width: "150px",
      height: "50px",
      background: "#FF881A",
      fontFamily: "Montserrat",
      fontSize: "15px",
      fontWeight: 600,
      color: "#1D1D1F",
      borderRadius: "8px"
    },
    "& .confirm-btn.Mui-disabled": {
      opacity: 0.6
    }
  }
}));

const VideoEventStyled = styled(Box)(({ theme }) => ({
  "& .bottomContent": {
    display: "flex",
    marginTop: "20px",
    "& .bottomContent-action": {
      flex: "0 0 60%",
      maxWidth: "60%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      "& .function_event": {
        display: "flex",
        gap: "24px",
        position: "relative",
        bottom: 0,
        [theme.breakpoints.down(1440)]: {
          flex: "0 0 100%",
          justifyContent: "center",
          height: "50px"
        }
      }
    },
    "& .recentGame": {
      flex: "0 0 40%",
      maxWidth: "40%",
      marginLeft: 10,
      "& .browseRecentGame_box":{
        background: "transparent",
        padding: 0,
        "& .listRecentGame": {
          padding: 0,
          "& .recentGameItem": {
            borderRadius: "10px",
            minWidth: "unset",
            "& .backgroundBlurGameItem": {
              borderRadius: "10px"
            },
            "& .content_text": {
              padding: "8px 70px"
            }
          }
        }
      }
    }
  },

  "& .sidePov, .botPov": {
    "& .title_text": {
      fontFamily: "Poppins",
      fontSize: "18px",
      fontWeight: 500,
      color: "#CFD1D4"
    }
  },
  "& .function_event": {
    display: "flex",
    gap: "24px",
    position: "absolute",
    bottom: "42%",
    "& .add-event-btn": {
      position: "unset"
    }
  },

  "& .container-isNotGrid": {
    "& .header": {
      "& .btnHeader": {
        padding: "16px 24px"
      }
    },
    "& .video-player": {
      flex: "0 0 80%",
      maxWidth: "80%",
      position: "relative",

      "& .controllerAreaWrapper": {
        bottom: "48px"
      }
    },
    "& #add_event-isNotGrid": {
      position: "absolute",
      bottom: 100,
      zIndex: 9999,
      left: "50%",
      transform: "translate(-50%, -50%)",
      "& .function_event": {
        display: "flex",
        gap: "24px",
        position: "relative"
      },
      "& .add-event-btn": {
        background: "#00000099",
        border: "1px solid #FF881A",
        color: "#FF881A",
        textTransform: "capitalize",
        padding: "10px 16px",
        fontFamily: "Montserrat",
        fontWeight: 600,
        fontSize: "16px",
      }
    },
    "& #video_controller-isNotGrid": {
      position: "absolute",
      width: "450px",
      bottom: 8,
      zIndex: 9999,
      left: "50%",
      transform: "translateX(calc(-450px / 2))",
      "& .controller-wrapper": {
        borderBottom: "2px solid #FF881A",
        borderRadius: 10,
        "& .video-controll-btn": {
          "& p": {
            fontFamily: "Montserrat",
            fontWeight: 600,
            fontSize: "16px",
            color: "#FF881A"
          },
          "& .video-controll-icon": {
            width: 34,
            height: 36
          }
        }
      },
    },
    "& .bottomContent div.bottomContent-action": {
      flex: "0 0 70%",
      maxWidth: "70%",
      justifyContent: "unset",
      "& .bottom_event_table": {
        "& .tableContent": {
          maxHeight: "calc(500px - 44px)"
        }
      }
    },
    "& .bottomContent .recentGame": {
      flex: "0 0 calc(30% - 16px)",
      maxWidth: "calc(30% - 16px)",
      marginLeft: "16px",
      "& p": {
        textAlign: "center"
      },
      "& .browseRecentGame_box": {
        maxHeight: "500px",
        overflowY: "auto",
        "& .listRecentGame": {
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "8px",
          "& .recentGameItem": {
            marginRight: 0,
            borderRadius: "10px",
            boxShadow: "-7.29px 5.83px 17.49px 0px #00000040 inset",
            width: "100%",
            maxWidth: "350px", 
          }
        }
      }
    }
  }
}));

const EventTableStyled = styled(Box)(({ theme }) => ({
  width: "100%",
  "& .header": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 20,
    "& .btnHeader": {
      background: "#00000099",
      border: "1px solid #FF881A",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      borderRadius: 8,
      padding: "4px 16px",
      textTransform: "none"
    }
  },
  "& .tableContent": {
    overflowY: "auto",
    maxHeight: "calc(500px - 80px)",
    "& .tableRow": {
      height: "40px"
    },
    "& .tableRow:nth-child(even)":{
      background: "#252527",
      height: "30px"
    },
    "& .tableCell": {
      border: 0
    }
  }
}));

const CommentModal = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  justifyContent: "center",
  alignItems: "center",
  color: "white",

  "& .container": {
    backgroundColor: "#252527",
    width: 600,
    padding: 40,
    paddingRight: 0,
    position: "relative",
    boxShadow: "-5px 4px 12px 0px #00000040 inset",
    borderRadius: "10px",
    maxHeight: 600,
    overflowY: "auto"

  },

  "& .close-btn": {
    width: 24,
    height: 24,
    position: "absolute",
    right: 0,
    top: 16
  },

  "& .container.modal-comment .modal-label": {
    color: "#CFD1D4",
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: 18,
    textAlign: "center",
  },

  "& .comment-container": {
    maxHeight: 450,
    overflowY: "auto",
    paddingRight: 40
  },

  "& .input-row": {
    display: "flex",
    flexDirection: "row",
    alignItems: "Center",
    height: 50,
    width: "100%",
    marginTop: 30,
    gap: 24,
    overflow: "hidden",
    position: "relative",
    paddingRight: 40,
    boxSizing: "border-box",
    "& .sticker-input": {
      position: "absolute",
      left: 8,
      cursor: "pointer",
      zIndex: 99
    },
    "& .input": {
      height: "100%",
      flex: 1,
      paddingLeft: 50,
      paddingRight: 20,
      borderRadius: 10,
      boxShadow: "4px 4px 4px 0px #00000040 inset",
      borderBottom: "1px solid #FF881A",
      color: "#CFD1D4"
    },
    "& .input::placeholder": {
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "16px"
    },
    "& .input:before, .input:after": {
      transform: "scaleX(0)"
    },
    "& .send-btn": {
      height: "100%",
      width: 100,
      backgroundColor: "#FF881A",
      borderRadius: 8,
      textTransform: "none",
      fontFamily: "Montserrat",
      fontWeight: 600,
      fontSize: "16px"
    },
  },

  "& .no-comment-text": {
    marginTop: 50,
    marginBottom: 70,
  }
  
}))

const TopMenuStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  height: 60,
  marginTop: 15,
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",

  "& *": {
    boxSizing: 'border-box'
  },

  "& .top-menu-back-btn": {
    height: '100%',
    width: '60px',
    cursor: 'pointer',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: "#252527",
  },
  
  "& .top-menu-item": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 25,
    paddingRight: 25,
    backgroundColor: "#252527",
    height: "100%",
    borderRadius: 10,
    textTransform: 'none',

    "&.second-item": {
      marginLeft: '10px'
    }
  },

  "& .top-menu-label": {
    color: "#CFD1D4",
  },

  "& .menu-icon": {
    width: 30,
    height: 30,
    marginLeft: 10
  }
}))

const SidePOVStyled = styled(Box)(({ theme }) => ({
  width: 150,
  overflowY: "scroll",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  color: "#CFD1D4",
  flex: "0 0 calc(20% - 16px)",
  maxWidth: "0 0 calc(20% - 16px)",
  "& .listUser_teamVideo": {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    "& .user_teamVideo": {
      flex: "0 0 calc(50% - 10px)",
      maxWidth: "calc(50% - 10px)"
    }
  }
}))

const BottomPOVStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  color: "#CFD1D4",
  "& .listUser_teamVideo": {
    display: "flex",
    maxWidth: "500px",
    overflowX: "auto",
    "& .user_teamVideo-name": {
      fontFamily: "Poppins",
      fontSize: "18px",
      fontWeight: 500,
      width: "80%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textAlign: "center"
    }
  }
}))

const BottomEventTableStyled = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  alignSelf: "center",
}))

const RightEventTableStyled = styled(Box)(({ theme }) => ({
  flex: "0 0 calc(40% - 10px)",
  maxWidth: "calc(40% - 10px)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  overflowY: "scroll",
  marginLeft: 10,
}))

const VideoAreaStyled = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  marginTop: 30,
  flexDirection: "row",
  alignItems: "stretch",
  justifyContent: "space-between",
  maxHeight: "56.25%", // ratio 16:9
  "& .video-player": {
    flex: "0 0 60%",
    maxWidth: "60%",
  }
}))

const VideoControllerStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "auto",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: 20,
  paddingLeft: 10,
  paddingRight: 10,
  [theme.breakpoints.down(1440)]:{
    width: "100%",
    flexWrap: "wrap",
    rowGap: "20px",
    boxSizing: "border-box",
  },

  "& .controller-wrapper": {
    height: 50,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    flex: 1,
    borderBottomWidth: 3,
    borderStyle: "solid",
    borderBottomColor: "#FF881A",
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    border: 10,
    marginRight: 25,
    borderRadius: 10,
    overflow: "hidden",
    [theme.breakpoints.down(1440)]: {
      flex: "0 0 calc(100% - 25px)",
    }
  },

  "& .add-event-btn": {
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 20,
    paddingRight: 20,
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: "#FF881A",
    overflow: "hidden",
    color: "#FF881A",
    borderRadius: 10,
    textTransform: "none",
    backgroundColor: "black"
  },

  "& .video-controll-btn": {
    color: "#FF881A",
  },

  "& .video-controll-icon": {
    width: 50,
    height: 40
  }
}))

const StyledFolderModal = styled(Box)(({ theme }) => ({
  background: "#1D1D1F",
  borderRadius: "10px",
  boxShadow: "-5px 4px 12px 0px #00000040 inset",
  padding: "40px",
  position: "relative",

  "& .MuiPaper-root": {
    maxWidth: "800px",
    // width: "700px",
  },

  "& .MuiIconButton-root": {
    height: "24px",
    width: "24px",
    padding: "4px",
    position: "absolute",
    top: "16px",
    right: "16px",
  },

  "& .MuiTypography-h6": {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "28px",
    textAlign: "center",
    color: "#CFD1D4",
    marginBottom: 32,
  },

  "& .MuiTypography-body1": {
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "26px",
    textAlign: "center",
    color: "#676767",
    // margin: "24px auto",
    // width: "700px"
  },

}))
// Customizable Area End
