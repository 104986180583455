import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef, useCallback, Fragment } from "react";
import ReactHlsPlayer from "react-hls-player";
import _ from 'lodash'
import Hls from 'hls.js';
import { toast } from "react-toastify";
import { CHANNEL_TYPE_MESSAGE } from "./constant";
import { Box, styled } from "@material-ui/core";
import { getColorForValue } from "./CustomKeyboards/Events.web";
import ImageComponent from "./ImageComponent.web";
const muteIcon = require("./assets/button_mute.svg")
const unMuteIcon = require("./assets/button_unmute.svg")
const imageAvatar = require('./assets/image_avatar.svg');

interface Marker {
  id: any;
  action?: any;
  subAction?: any;
  value?: any;
  reason?: any;
  subReason?: any;
  time: any;
  photo?: string;
}

interface MarkerPosition {
  time: number;
  position: number;
}

interface Quality {
  height: number;
  width: number;
  bitrate: number;
  name: string;
}

interface Props {
  videoUrl: string;
  markers: Marker[];
  clipDuratonBefore: number;
  clipDuratonAfter: number;
  speed: number;
  buttonAddEventComponent: false | JSX.Element;
  controllerVideoPlayer: (isModeFullScreen: boolean) => JSX.Element;
}

const renderPlayIcon = () => (
  <svg
    height="20"
    id="Layer_1"
    version="1.1"
    viewBox="0 0 512 512"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M224,435.8V76.1c0-6.7-5.4-12.1-12.2-12.1h-71.6c-6.8,0-12.2,5.4-12.2,12.1v359.7c0,6.7,5.4,12.2,12.2,12.2h71.6   C218.6,448,224,442.6,224,435.8z"
        fill="#CFD1D4"
      />
      <path
        d="M371.8,64h-71.6c-6.7,0-12.2,5.4-12.2,12.1v359.7c0,6.7,5.4,12.2,12.2,12.2h71.6c6.7,0,12.2-5.4,12.2-12.2V76.1   C384,69.4,378.6,64,371.8,64z"
        fill="#CFD1D4"
      />
    </g>
  </svg>
);

const renderPauseIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_23187_5042)">
      <path
        d="M4 11.9994L4 8.43941C4 4.01941 7.13 2.20941 10.96 4.41941L14.05 6.19941L17.14 7.97941C20.97 10.1894 20.97 13.8094 17.14 16.0194L14.05 17.7994L10.96 19.5794C7.13 21.7894 4 19.9794 4 15.5594V11.9994Z"
        fill="#CFD1D4"
        stroke="#CFD1D4"
        stroke-width="0.807813"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_23187_5042">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const renderFullScreenIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 15C4.26522 15 4.51957 15.1054 4.70711 15.2929C4.89464 15.4804 5 15.7348 5 16L5 19H8C8.26522 19 8.51957 19.1054 8.70711 19.2929C8.89464 19.4804 9 19.7348 9 20C9 20.2652 8.89464 20.5196 8.70711 20.7071C8.51957 20.8946 8.26522 21 8 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19L3 16C3 15.7348 3.10536 15.4804 3.29289 15.2929C3.48043 15.1054 3.73478 15 4 15ZM20 15C20.2449 15 20.4813 15.09 20.6644 15.2527C20.8474 15.4155 20.9643 15.6398 20.993 15.883L21 16V19C21.0002 19.5046 20.8096 19.9906 20.4665 20.3605C20.1234 20.7305 19.6532 20.9572 19.15 20.995L19 21H16C15.7451 20.9997 15.5 20.9021 15.3146 20.7272C15.1293 20.5522 15.0178 20.313 15.0028 20.0586C14.9879 19.8042 15.0707 19.5536 15.2343 19.3582C15.3979 19.1627 15.6299 19.0371 15.883 19.007L16 19H19V16C19 15.7348 19.1054 15.4804 19.2929 15.2929C19.4804 15.1054 19.7348 15 20 15ZM19 3C19.5046 2.99984 19.9906 3.19041 20.3605 3.5335C20.7305 3.87659 20.9572 4.34684 20.995 4.85L21 5V8C20.9997 8.25488 20.9021 8.50003 20.7272 8.68537C20.5522 8.87071 20.313 8.98224 20.0586 8.99717C19.8042 9.01211 19.5536 8.92933 19.3582 8.76574C19.1627 8.60215 19.0371 8.3701 19.007 8.117L19 8V5L16 5C15.7451 4.99972 15.5 4.90212 15.3146 4.72715C15.1293 4.55218 15.0178 4.31305 15.0028 4.05861C14.9879 3.80416 15.0707 3.55362 15.2343 3.35817C15.3979 3.16271 15.6299 3.0371 15.883 3.007L16 3L19 3ZM8 3C8.25488 3.00028 8.50003 3.09788 8.68537 3.27285C8.87071 3.44782 8.98224 3.68695 8.99717 3.9414C9.01211 4.19584 8.92933 4.44638 8.76574 4.64183C8.60215 4.83729 8.3701 4.9629 8.117 4.993L8 5L5 5L5 8C4.99972 8.25488 4.90212 8.50003 4.72715 8.68537C4.55218 8.87071 4.31305 8.98224 4.05861 8.99717C3.80416 9.01211 3.55362 8.92933 3.35817 8.76574C3.16271 8.60215 3.0371 8.3701 3.007 8.117L3 8L3 5C2.99984 4.49542 3.19041 4.00943 3.5335 3.63945C3.87659 3.26947 4.34684 3.04284 4.85 3.005L5 3L8 3Z"
      fill="#CFD1D4"
    />
  </svg>
);

function CustomVideoPlayer(props: Props, ref: any) {
  const channel = useRef(new BroadcastChannel('sw-messages-error')).current;
  const { videoUrl, markers } = props;
  const [videoDuration, setVideoDuration] = useState(0);
  const [isFullScreenMode, setIsFullScreenMode] = useState(false);
  const [isMuteVideo, setIsMuteVideo] = useState(false);
  const [hover, setHover] = useState(false)
  const [availableQualities, setAvailableQualities] = useState<Quality[]>([]);
  const [currentQuality, setCurrentQuality] = useState<string>('Auto'); const [currentMarker, setCurrentMarker] = useState<Marker | null>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const videoRef: any = useRef(null);
  const hlsRef = useRef<Hls | null>(null);
  const timelineRef: any = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useImperativeHandle(ref, () => {
    return {
      getCurrentTime: () => currentTime,
      toggleFullScreen,
      play: () => {
        videoRef?.current?.play();
        setIsPlaying(true);
      },
      pause: () => {
        videoRef?.current?.pause();
        setIsPlaying(false);
      },
      back: () => {
        let nextMarker = null;
        for (let i = markers.length - 1; i >= 0; i--) {
          if (markers[i].time + props.clipDuratonAfter < currentTime) {
            nextMarker = markers[i];
            break;
          }
        }

        const newTime = nextMarker?.time - props.clipDuratonBefore;
        if (newTime > 0) {
          videoRef.current.currentTime = newTime;
          setCurrentTime(newTime);
        }
      },
      back5s: () => {
        videoRef.current.currentTime -= 5;
      },
      back15s: () => {
        videoRef.current.currentTime -= 15;
      },
      next: () => {
        let nextMarker = null;
        for (let i = 0; i < markers.length; i++) {
          if (markers[i].time - props.clipDuratonBefore > currentTime) {
            nextMarker = markers[i];
            break;
          }
        }

        const newTime = nextMarker?.time - props.clipDuratonBefore;
        if (newTime > 0) {
          videoRef.current.currentTime = newTime;
          setCurrentTime(newTime);
        }
      },
      next5s: () => {
        videoRef.current.currentTime += 5;
      },
      next15s: () => {
        videoRef.current.currentTime += 15;
      },
      previousMarker: () => {
        let markerPrevious = null;
        markers.sort((currentMark, nextMark) => {
          return currentMark.time - nextMark.time
        })
        for (const markerElm of markers) {
          if (currentTime > markerElm.time) {
            markerPrevious = markerElm;
          }
        }
        if(markers[0].time > currentTime){
        videoRef.current.currentTime = 0;
          setCurrentMarker(null);
          setCurrentTime(0);
        }else {
          videoRef.current.currentTime = markerPrevious?.time || markers[0].time;
          setCurrentMarker(markerPrevious || markers[0]);
          setCurrentTime(markerPrevious?.time || markers[0].time);
        }
      },
      nextMarker: () => {
        let markerNext = null;
        if(!markers.length) return;
        markers.sort((currentMark, nextMark) => {
          return nextMark?.time - currentMark?.time
        })

        for (const markerElm of markers) {
          if (currentTime < markerElm.time) {
            markerNext = markerElm;
          }
        }
        setCurrentMarker(markerNext || markers[0])
        setCurrentTime(markerNext?.time || markers[0]?.time)
        videoRef.current.currentTime = markerNext?.time || markers[0]?.time;
      },
      getCurrentMarker: () => {
        const currentMark = handleGetCurrentMarker(props.markers, currentTime, props.clipDuratonBefore, props.clipDuratonAfter)
        setCurrentMarker(currentMark);
        return currentMark;
      },
      isVideoFullScreen: () => {
        return isFullScreenMode
      }
    };
  }, [currentTime, videoRef, markers, currentMarker, isFullScreenMode]);

  // Debounced toast error function
  const debouncedToastError = useCallback(
    _.debounce((message: string) => {
      toast.error(message);
    }, 3000),
    []
  );
  const debouncePostMessage = useCallback(
    _.debounce(() => {
      sendErrorMessageToChannel();
    }, 3000),
    []
  );

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data && event.data.type === 'ERROR') {
        if (event.data.message)
          debouncedToastError(event.data.message)
        pauseVideo();
      }
    };

    channel.addEventListener('message', handleMessage);

    return () => {
      channel.removeEventListener('message', handleMessage);
      channel.close();
    };
  }, [channel]);

  function pauseVideo() {
    if (videoRef.current) {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  // Function to go to a marker's time
  function goToMarker(marker: MarkerPosition) {
    setCurrentTime(marker.time);
    videoRef.current.currentTime = marker.time;
  }

  function handleTimelineClick(event: any) {
    const timelineWidth = timelineRef.current.offsetWidth;
    const clickPositionX =
      event.clientX - timelineRef.current.getBoundingClientRect().left;
    const newTime = (clickPositionX / timelineWidth) * videoDuration;
    setCurrentTime(newTime);
    videoRef.current.currentTime = newTime;
  }

  function calculateMarkerAndProgressPositions() {
    const progressPosition = (currentTime / videoDuration) * 100;
    const markerPositions: MarkerPosition[] = markers.map((marker) => ({
      time: marker.time,
      position: (marker.time / videoDuration) * 100,
    }));
    return { progressPosition, markerPositions };
  }

  function handleVideoLoaded() {
    setVideoDuration(videoRef.current.duration);
  }

  function handleTimeUpdate() {
    setCurrentTime(videoRef.current.currentTime);
  }

  useEffect(() => {
    videoRef.current.addEventListener("loadedmetadata", handleVideoLoaded);
    videoRef.current.addEventListener("timeupdate", handleTimeUpdate);

    if (Hls.isSupported()) {
      const hls = new Hls({
        debug: true,
        maxBufferLength: 30,
        maxMaxBufferLength: 60,
        maxBufferSize: 60 * 1000 * 1000,
        maxBufferHole: 0.5,
      });
      hlsRef.current = hls;

      hls.loadSource(videoUrl);
      hls.attachMedia(videoRef.current);

      hls.on(Hls.Events.ERROR, (event, data) => {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              console.error("Network error")
              debouncePostMessage();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              console.error("Media error")
              sendErrorMessageToChannel()
              hls.recoverMediaError();
              break;
            default:
              sendErrorMessageToChannel()
              hls.destroy();
              break;
          }
        } else {
          sendErrorMessageToChannel();
        }
      });
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        const levels = hls.levels;
        const qualities = levels.map(level => {
          return {
            height: level.height,
            width: level.width,
            bitrate: level.bitrate,
            name: `${level.height}`
          };
        });
        setAvailableQualities(qualities);
        setCurrentQuality('Auto'); // Default to auto quality
        console.info('HLS Manifest parsed. Available levels:', hls.levels);
      });

      hls.on(Hls.Events.LEVEL_SWITCHING, (event, data) => {
        const currentLevel = hls.levels[data.level];
        console.info(`Switching to quality level: ${data.level} with bitrate: ${currentLevel.bitrate}`);
      });

      hls.on(Hls.Events.LEVEL_SWITCHED, (event, data) => {
        const currentLevel = hls.levels[data.level];
        console.info(`Switched to quality level: ${data.level} with bitrate: ${currentLevel.bitrate}`);
      });
    } else if (videoRef.current?.canPlayType('application/vnd.apple.mpegurl')) {
      videoRef.current.src = videoUrl;
    }

    const currentMark = handleGetCurrentMarker(props.markers, currentTime, props.clipDuratonBefore, props.clipDuratonAfter)
    setCurrentMarker(currentMark);
    return () => {
      videoRef.current.removeEventListener("loadedmetadata", handleVideoLoaded);
      videoRef.current.removeEventListener("timeupdate", handleTimeUpdate);
      // Remove implementation HLS library
      hlsRef.current?.destroy();
      hlsRef.current = null;
    };
  }, []);

  useEffect(() => {
    videoRef.current.playbackRate = props.speed;
  }, [props.speed]);

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (document.fullscreenElement) {
        setIsFullScreenMode(true);
      } else {
        setIsFullScreenMode(false);
      }
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  function handleQualityChange(quality: string) {
    if (hlsRef) {
      // Find the level corresponding to the selected quality
      const selectedLevel = availableQualities.findIndex(qualityLevel => qualityLevel.height.toString() === quality);
      // Set the level in Hls.js (-1 means auto quality)
      if (hlsRef.current)
        hlsRef.current.currentLevel = selectedLevel >= 0 ? selectedLevel : -1;
      setCurrentQuality(quality);
    }
  };

  function sendErrorMessageToChannel(errorMessage?: string) {
    if (navigator.serviceWorker.controller)
      navigator.serviceWorker.controller.postMessage({ type: CHANNEL_TYPE_MESSAGE.SEND_ERROR_MESSAGE, message: errorMessage });
  }

  function resetErrorMessageChannel() {
    if (navigator.serviceWorker.controller)
      navigator.serviceWorker.controller.postMessage({ type: CHANNEL_TYPE_MESSAGE.RESET_ERROR_MESSAGE });
  }

  function togglePlayPause() {
    if (videoRef.current.paused) {
      videoRef.current.play();
      hlsRef?.current?.startLoad();
      setIsPlaying(true);
      resetErrorMessageChannel()
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  }

  function toggleFullScreen() {
    if (document.fullscreenElement) {
      setIsFullScreenMode(false);
      document.exitFullscreen();
    }
    else {
      setIsFullScreenMode(true);
      document.body.requestFullscreen();
    }
  }

  const renderVideo = () => {
    const isStreamUrl = videoUrl.includes("m3u8");
    return (
      <Fragment>
        {isStreamUrl ? (
          <ReactHlsPlayer
            controls={false}
            playerRef={videoRef}
            src={videoUrl}
            style={webStyles.video}
            onEnded={() => setIsPlaying(false)}
          />
        ) : (
          <video ref={videoRef} controls={false} style={webStyles.video} onEnded={() => setIsPlaying(false)}>
            <source src={videoUrl} type="video/mp4" />
          </video>
        )}
      </Fragment>
    )
  };

  useEffect(() => {
    const currentMark = handleGetCurrentMarker(props.markers, currentTime, props.clipDuratonBefore, props.clipDuratonAfter)
    setCurrentMarker(currentMark);
  }, [currentTime]);

  const renderPOV = () => {

    return (
      <>
        {!!currentMarker ? (
          <MarkerVideoStyled style={{ marginBottom: isFullScreenMode ? "40px" : "10px"}}>
            <div className="content">
              <ImageComponent
                widthImage="80px"
                heightImage="80px"
                style={webStyles.povThumb}
                src={currentMarker.photo || imageAvatar}
                alt="i"
                fallbackSrc=""
              />
              <div style={webStyles.eventDetailWrapper}>
                <div style={webStyles.eventDetailRow}>
                  <div style={webStyles.eventDetailText}>ACTION:</div>
                  <div style={webStyles.eventDetailText}>{currentMarker?.action}</div>
                </div>
                <div style={webStyles.eventDetailRow}>
                  <div style={webStyles.eventDetailText}>SUB ACTION:</div>
                  <div style={webStyles.eventDetailText}>{currentMarker?.subAction}</div>
                </div>
                <div style={webStyles.eventDetailRow}>
                  <div style={webStyles.eventDetailText}>VALUE:</div>
                  <div style={{ ...webStyles.eventDetailText, color: getColorForValue(currentMarker?.value?.toLowerCase()) }} >{currentMarker?.value}</div>
                </div>
                <div style={webStyles.eventDetailRow}>
                  <div style={webStyles.eventDetailText}>REASON:</div>
                  <div style={webStyles.eventDetailText}>{currentMarker?.reason}</div>
                </div>
                <div style={webStyles.eventDetailRow}>
                  <div style={webStyles.eventDetailText}>SUB REASON:</div>
                  <div style={webStyles.eventDetailText}>{currentMarker?.subReason}</div>
                </div>
              </div>
            </div>
          </MarkerVideoStyled>
        ) : undefined}
      </>
    )
  };

  const renderTimeText = () => (
    <div style={webStyles.timeAreaWrapper}>
      <div style={webStyles.timeText}>{formatTime(currentTime)}</div>
      <div style={webStyles.timeText}>{formatTime(videoDuration)}</div>
    </div>
  );

  const renderQualitySelection = () => {
    const cloneAvailableQualities = [...availableQualities]
    cloneAvailableQualities.push({ height: -1, width: -1, bitrate: 0, name: "Auto" });
    return (
      <QualitySelectionStyled
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div className="current-quality">
          {!isNaN(+currentQuality) ? currentQuality + "p" : currentQuality}
        </div>
        {hover && (
          <div className="quality-options">
            {cloneAvailableQualities.reverse().map((quality) => (
              <div
                key={quality.height}
                className={`quality-option ${quality.name === currentQuality ? "quality-option--active" : ""}`}
                onClick={() => handleQualityChange(quality.name)}
              >
                {quality.name}
              </div>
            ))}
          </div>
        )}
      </QualitySelectionStyled>
    )
  }
  const renderController = () => (
    <div style={webStyles.controllerWrapper}>
      {renderPlayPauseBtn()}
      {renderTimelineBar()}
      {renderQualitySelection()}
      {renderMuteSound()}
      {renderFullScreenBtn()}
    </div>
  );

  const renderPlayPauseBtn = () => (
    <div
      onClick={() => {
        togglePlayPause();
      }}
      style={webStyles.playBtn}
    >
      {isPlaying ? <>{renderPlayIcon()}</> : <>{renderPauseIcon()}</>}
    </div>
  );

  function handleToggleMuteVideo() {
    if (videoRef.current) {
      const isVideoMuted = videoRef.current.muted;
      videoRef.current.muted = !isVideoMuted;
      setIsMuteVideo(!isVideoMuted);
    }
  }

  const renderMuteSound = () => (
    <div style={{margin: "0 8px", cursor: "pointer", padding: 4}} onClick={handleToggleMuteVideo}>
      <img src={isMuteVideo ? muteIcon : unMuteIcon} alt="s" width={24} height={24} />
    </div>
  )

  const renderFullScreenBtn = () => (
    <div onClick={toggleFullScreen} style={webStyles.pauseBtn}>
      {renderFullScreenIcon()}
    </div>
  );

  const renderTimelineBar = () => (
    <div
      ref={timelineRef}
      style={webStyles.timeLineBar}
      onClick={handleTimelineClick}
    >
      <div
        style={{
          ...webStyles.currentTimePoint,
          left: `${calculateMarkerAndProgressPositions().progressPosition}%`,
        }}
      ></div>
      {calculateMarkerAndProgressPositions().markerPositions.map(
        (marker: MarkerPosition, index) => (
          <div
            key={index}
            style={{
              ...webStyles.markerPoint,
              left: `${marker.position}%`,
            }}
            onClick={(e) => {
              goToMarker(marker);
            }}
            onMouseEnter={(e: any) =>
              (e.target.style.backgroundColor = "white")
            }
            onMouseLeave={(e: any) =>
              (e.target.style.backgroundColor = "#CFD1D4")
            }
          ></div>
        )
      )}
    </div>
  );



  return (
    <VideoPlayerContainerStyled
      id="video-player-container"
      style={{
        position: isFullScreenMode ? "fixed" : "relative",
      }} >
      {renderVideo()}
      <div className="controllerAreaWrapper">
        <div style={{...webStyles.controllerAreaContainer, minHeight: isFullScreenMode ? "255px" : ""} }>
          {renderPOV()}
          {renderTimeText()}
          {renderController()}
          {props.buttonAddEventComponent}
          {props.controllerVideoPlayer(true)}
        </div>
      </div>
    </VideoPlayerContainerStyled>
  );
}

function formatTime(time: number) {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = Math.floor(time % 60);
  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )}:${String(seconds).padStart(2, "0")}`;
}

function handleGetCurrentMarker(markers: Marker[], currentTime: number, clipDurationBefore: number, clipDurationAfter: number) {
  let currentMark = null;
  for (const markerElm of markers) {
    if (markerElm.time - clipDurationBefore <= currentTime && markerElm.time + clipDurationAfter >= currentTime) {
      currentMark = markerElm;
      break;
    }
  }
  return currentMark
}

export default forwardRef(CustomVideoPlayer);

type WebStyles = {
  [key: string]: React.CSSProperties;
};

const webStyles: WebStyles = {
  video: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0
  },
  controllerAreaContainer: {
    rowGap: "5px",
    width: "95%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  povThumb: {
    height: 100,
    aspectRatio: "1.0",
    borderRadius: 5,
    overflow: "hidden",
    marginRight: 8,
    objectFit: "cover",
  },
  eventDetailWrapper: {
    alignSelf: "flex-start",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    height: 100,
  },
  eventDetailRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: 150
  },
  eventDetailText: {
    color: "#CFD1D4",
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "10px",
  },
  timeAreaWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flex: 1,
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  timeText: {
    textAlign: "center",
    fontSize: 14,
    color: "#CFD1D4",
    marginLeft: 35,
    marginRight: 90,
    marginBottom: -7,
  },
  controllerWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  playBtn: {
    width: 25,
    height: 25,
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  timeLineBar: {
    height: 10,
    flex: 1,
    backgroundColor: "#CFD1D4",
    cursor: "pointer",
    alignSelf: "center",
    position: "relative",
    marginLeft: 10,
    borderRadius: 10
  },
  currentTimePoint: {
    position: "absolute",
    top: 0,
    height: 14,
    width: 14,
    marginTop: -4,
    marginLeft: -4.5,
    borderStyle: "solid",
    borderRadius: 14,
    borderWidth: 2,
    borderColor: "#FF881A",
    backgroundColor: "#252527",
    zIndex: 10,
  },
  markerPoint: {
    position: "absolute",
    top: 0,
    height: 10,
    width: 10,
    marginTop: -2,
    borderStyle: "solid",
    borderRadius: 10,
    borderWidth: 2,
    borderColor: "#FF881A",
    transform: "translateX(-50%)",
    backgroundColor: "#CFD1D4",
    zIndex: 2,
  },
  pauseBtn: {
    width: 25,
    height: 25,
    cursor: "pointer",
  },
};

const VideoPlayerContainerStyled = styled("div")(({ theme }) => ({
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  zIndex: 9999,
  background: "black",
  width: "100%",
  paddingTop: "56.25%", //ratio 16:9
  "& .controllerAreaWrapper": {
    position: "absolute",
    bottom: 10,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  "& .add-event-btn": {
    background: "#00000099",
    border: "1px solid #FF881A",
    borderRadius: "8px",
    padding: "10px 16px",
    color: "#FF881A",
    position: "absolute",
    bottom: "40%",
    "& p": {
      fontSize: "16px",
      fontFamily: "Montserrat",
      fontWeight: 600,
      textTransform: "capitalize",
    }
  },
  "& .add-event-btn, .controller-wrapper":{
    // Go to index.css file to set display block when fullscreen
    display: "none",
  },
  "& .controller-wrapper": {
    borderBottom: "2px solid #FF881A",
    borderRadius: "8px",
    "& .video-controll-btn": {
      color: "#FF881A",
      "& p": {

        fontFamily: "Montserrat",
        fontWeight: 600,
        fontSize: "16px",
      },
      "& .video-controll-icon": {
        width: "36px",
        height: "36px"
      }
    }
  }
}))

const QualitySelectionStyled = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "inline-block",
  width: "100%",
  maxWidth: "48px",
  "& .current-quality": {
    textAlign: "right",
    textTransform: "capitalize",
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#CFD1D4",
    borderRadius: "4px",
    cursor: "pointer",
  },

  "& .quality-options": {
    left: "50%",
    transform: "translateX(-50%)",
    textAlign: "center",
    width: "80px",
    position: "absolute",
    bottom: 20,
    backgroundColor: "#1D1D1F",
    border: "1px solid #FF881A",
    borderRadius: "10px",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
    zIndex: 10,
    padding: 8,
    "& .quality-option": {
      borderRadius: 8,
      padding: "8px 16px",
      cursor: "pointer",
      color: "#CFD1D4",
      fontFamily: "Poppins",
      fontSize: "14px"
    },
    "& .quality-option--active, .quality-option:hover": {
      backgroundColor: "#FF881A",
      color: "#1D1D1F"
    }
  }
}))

const MarkerVideoStyled = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  paddingLeft: 30,
  boxSizing: "border-box",
  "& .content::before": {
    content: "''",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#000000BF",
    borderRadius: "10px",
    zIndex: -11
  },
  "& .content": {
    width: "fit-content",
    position: "relative",
    zIndex: 2,
    display: "flex",
    gap: 8,
    flexDirection: "row",
    alignItems: "center",
    padding: 8,
    overflow: "hidden",
  }
}))
