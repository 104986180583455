import React from 'react';
import ApexCharts from 'react-apexcharts';
export const  CustomBarChart  = () =>{
 
       const  options =  {
          series: [
            {
              name: 'Missed-OPs',
              data: [70, 60, 10, 30, 40, 80, 50],
            },
          ],
          chart: {
            type: 'bar',
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '10%',
              endingShape: 'rounded',
              colors: {
                ranges: [],
                backgroundBarOpacity: 0,
                backgroundBarRadius: 0,
              },
              dataLabels: {
                position: 'top',
              },
              borderRadius: 5,
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val:any) {
              return `${val}%`;
            },
            offsetY: -20,
            style: {
              fontSize: '14px',
              colors: ['#fff'],
            },
            background: {
              enabled: true,
              foreColor: '#000',
              borderRadius: 4,
              borderWidth: 1,
              borderColor: '#F48024',
              dropShadow: {
                enabled: true,
                top: 2,
                left: 2,
                blur: 2,
                color: '#000',
                opacity: 0.2,
              },
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'vertical',
              gradientToColors: ['#FF881A'], // The ending color
              stops: [0, 100],
              opacityFrom: 1,
              opacityTo: 0.14, // Similar to the opacity for gradient end
            },
          },
          colors: ['#FFA726'],
          xaxis: {
            categories: [
              'Shot',
              'Assist',
              'Objective',
              'Flank',
              'Disengage',
              'Peak',
              'Utility',
            ],
            labels: {
              style: {
                colors: '#fff',
                fontSize: '12px',
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                colors: '#fff',
              },
            },
          },
          grid: {
            show: false,
          },
          tooltip: {
            enabled: true,
            y: {
              formatter: function (val:any) {
                return `${val} Missed-OPs`;
              },
            },
          },
      }
      return (
        <div>
          <ApexCharts options={options as any} series={options.series} type="bar" height={350} />
        </div>
      )
}
     