import React from "react";
import AnalyticsController, {
    Props,
} from "./AnalyticsController.web";
// Customizable Area Start
import { Box, Typography, Grid } from "@material-ui/core";
import { radialLogo, statBarLogo, teamLogo } from "./assets";
import RadialBarChart from '../../../components/src/RadialBarChart'
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { CustomBarChart } from "../../../components/src/AnalyticsBarStat.web";
import { StyledPagination } from "./Analytics.web";

// Customizable Area End

export default class AnalyticsStat extends AnalyticsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }


    render() {
    const { currentPage, totalPages } = this.state;

        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <NavigationMenu navigation={this.props.navigation} id="NavigationMenu" currentTabProp="stats" />
                <StyledGridItem>
                    <Grid className="container">
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="topBar">
                            <Box>left</Box>
                            <Box>right</Box>

                        </Grid>
                        <Grid className="teamView" >
                            {[1, 2, 3].map(item => (<Box className="teamBox">
                                <img src={teamLogo} height={'124px'} width={'124px'} />
                                <Box>
                                    <Typography className="text-style">Game Title</Typography>
                                    <Typography className="text-style ">Compitation Type</Typography>
                                    <Box className="flexGrid">
                                        <Typography className="text-style marked-event">Total marked events: </Typography>
                                        <span className="text-style marked-event">(23 42 43)</span>
                                    </Box>
                                    <Box className="flexGrid">

                                        <Typography className="text-style marked-event">Match Length: </Typography>
                                        <Typography className="text-style marked-event">13 min</Typography>

                                    </Box>
                                </Box>
                            </Box>))}

                        </Grid>
                        <Grid className="statView">
                            <Box className="card">
                                <Box className="stat-lable">
                                    <img src={radialLogo} height={31} width={31} />
                                    <Typography className="text-style stat-lable-text">Value Distribution</Typography>
                                </Box>
                                <RadialBarChart />
                                <Box className="radial-lable">
                                    <div className="label-circle made"></div><span className="stat-bottom-text">Made</span>
                                    <div className="label-circle missed"></div><span className="stat-bottom-text">Missed</span>
                                    <div className="label-circle missed-op"></div><span className="stat-bottom-text">Missed-op</span>


                                </Box>
                            </Box>
                            <Box className="card">
                                <Box className="stat-lable">
                                    <img src={radialLogo} height={31} width={31} />
                                    <Typography className="text-style stat-lable-text">Category Distribution</Typography>
                                </Box>

                                <RadialBarChart />
                                <Box className="radial-lable">
                                    <div className="label-circle made"></div><span className="stat-bottom-text">Made</span>
                                    <div className="label-circle missed"></div><span className="stat-bottom-text">Missed</span>
                                    <div className="label-circle missed-op"></div><span className="stat-bottom-text">Missed-op</span>

                                </Box>
                            </Box>
                            <Box className="card-conclusion">
                                <Box className="stat-lable">
                                    <img src={radialLogo} height={31} width={31} />
                                    <Typography className="text-style stat-lable-text">Category Distribution</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid className="statView">
                            <Box className="barCard">
                                <Box className="stat-lable">
                                    <img src={statBarLogo} height={31} width={31} />
                                    <Typography className="text-style stat-lable-text">Action distribution by # of Missed-OPs</Typography>
                                </Box>
                                <CustomBarChart />
                            </Box>
                            <Box className="barCard">
                                <Box className="stat-lable">
                                    <img src={statBarLogo} height={31} width={31} />
                                    <Typography className="text-style stat-lable-text">Reason distribution by # of Missed-OPs</Typography>
                                </Box>
                                <CustomBarChart />
                            </Box>
                        </Grid>
                    </Grid>
                </StyledGridItem>
                <StyledPagination
          data-test-id={"user-table-pagination"}
          count={totalPages}
          page={currentPage}
          onChange={this.handleChangePage}
        />
            </ThemeProvider>
            // Customizable Area End

        )
    }
}
// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff"
        }
    },
    typography: {
        h6: {
            fontWeight: 500
        },
        subtitle1: {
            margin: "20px 0px"
        }
    }
});

const StyledGridItem = styled('div')(({ theme }) => ({
    "& .container": {
        margin: 'auto',
        marginTop: '20px',
        width: "93vw",

    },
    "& .topBar": {
        display: 'flex',
        justifyContent: 'space-between'
    },
    "& .teamView": {
        display: 'flex',
        gap: '32px'
    },
    "& .teamBox": {
        display: 'flex',
        gap: '24px',
        border: '1px solid #FF881A',
        borderRadius: '10px',
        paddingRight: '16px',
        paddingLeft: '16px',
        columnGap: '16px',
        paddingTop: '8px',
        paddingBottom: '8px'

    },
    "& .flexGrid": {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '16px'
    },
    "& .card": {
        height: "430px",
        width: "400px",
        border: '1px solid #252527',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignContent: "center",
        alignItems: 'center',
        backgroundColor: "#252527",
        borderRadius: '10px'
    },
    "& .card-conclusion": {
        height: "430px",
        width: "700px",
        border: '1px solid #252527',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: "#252527",
        borderRadius: '10px'
    },
    "& .statView": {
        display: 'flex',
        gap: '16px',
        marginTop: '16px',
    },
    "& .radial-label": {
        gap: '4px',
    },
    "& .label-circle": {
        borderRadius: '50%',
        width: '15px',
        height: '15px',

    },
    "& .radial-lable": {
        display: 'flex',
        alignContent: 'center',
        gap: '4px'
    },
    "& .made": {
        backgroundColor: '#7ED321',
        border: '1px solid #7ED321',
    },

    "& .missed": {
        backgroundColor: '#DC3117',
        border: '1px solid #DC3117',
    },
    "& .missed-op": {
        backgroundColor: '#FF881A',
        border: '1px solid #FF881A',
    },
    "& .stat-lable": {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        gap: '4px'
    },
    "& .text-style": {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '28px',
        textAlign: 'left',
        color: "#CFD1D4"

    },
    "& .marked-event": {
        color: "#676767",
        fontWeight: 400,

    },
    "& .stat-lable-text": {
        fontSize: '24px',
        fontWeight: 400,

    },
    "& .stat-bottom-text": {
        fontFamily: 'Roboto, sans-serif', // Ensure a fallback font
        fontSize: '18px',
        fontWeight: 400,
        lineHeight: '21.09px',
        textAlign: 'left',
        color: "#CFD1D4"
    },
    "& .barCard":{
        border: '1px solid #252527',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: "#252527",
        borderRadius: '10px',
        width:'50%',
        padding:'20px'
    }

}))
// Customizable Area End
