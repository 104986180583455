import { ChangeEvent, RefObject } from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from 'react';
import DateObject from "react-date-object";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
export const configJSON = require("./config");

export interface GetTotalStorageResponse {
  errors?: string;
  team_storage: {
    used: number;
    total: number;
  }
}

export interface GetStorageByMonthResponse {
  errors?: string;
  team_usage_per_month: {
    // Key = "Jan 2024", "Feb 2024", ..
    [key: string]: string;
  }
}

export interface UserProfileData {
  error?: {};
  errors?: string[];
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      country: string;
      address: string;
      city: string;
      postal_code: string;
      account_id: number;
      full_name: string;
      gender: string;
      date_of_birth: string;
      team_name: string;
      email: string;
      role: string;
      photo: string;
      permissions: Array<string>;
      role_id: number;
      state: string;
      user_name:string;
      account_type: string;
      sport_position: string|null;
      sport_title: string|null;
      esport_title: string|null;
      in_game_role_name: string|null;
      invited_user: boolean;
    }
  }
}

export interface ChartData {
  id: string; label: string; usage: number; initialUsage: number;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  countryData: Array<{ name: string; code: string }>;
  statesName: string[];
  loadingScreen: boolean;
  formValue: {
    fullName: string;
    email: string;
    teamName: string;
    dateOfBirth: string;
    country: string;
    gender: string;
    state: string;
    currentPassword:string;
    newPassword:string;
    confirmPassword:string;
  };
  errorMessageOn: {
    dateOfBirth: string;
    country: string;
    fullName: string;
    teamName: string;
    gender: string;
    email: string;
    state:string;
    currentPassword:string;
    newPassword:string;
    confirmPassword:string;
  };
  enableCurrentPassword: boolean;
  enableNewPassword: boolean;
  enableConfirmPassword: boolean;
  isMaleBtnChecked: boolean;
  isFemaleBtnChecked: boolean;
  isOtherGenderBtnChecked: boolean;
  chartData: Array<ChartData>;
  token: string;
  userAvatar: string;
  selectedFile: File | null;
  onEdit: boolean;
  toastOpen: boolean;
  toastMessage: string;
  toastSuccess: boolean;
  deleteAccountModal: boolean;
  userId: number | null,
  user_name:string;
  account_type: string;
  sport_position: string|null;
  sport_title: string|null;
  esport_title: string|null;
  in_game_role_name: string|null;
  userRole: string,
  userRoleId: number|null,
  tab: string;
  isInvitedUser: boolean,
  isProfileUpdated: boolean | null;
  initialDateOpen: string;
  subscriptionDueDate:{
    validUntil: string;
  }
  storageUsedPercentage: number;
  totalStorage:number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getStatesApiCallId: string = "";
  getCountryCodeApiCallId: string = "";
  getUserProfileCallId: string = "";
  updateUserProfileCallId: string = '';
  deleteProfileCallId: string = '';
  changePasswordApiCallId: string = "";
  fileInputRef: RefObject<HTMLInputElement> = React.createRef();
  getDueDateCallId: string = "";
  getStorageByMonthCallId: string = "";
  getTotalStorageCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.handleUserProfileApiResponse = this.handleUserProfileApiResponse.bind(this);
    this.formValidation = this.formValidation.bind(this);
    this.handleEditProfile = this.handleEditProfile.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.fullNameValidation = this.fullNameValidation.bind(this);
    this.teamNameValidation = this.teamNameValidation.bind(this);
    this.handleDateInputField = this.handleDateInputField.bind(this);
    this.handleGender = this.handleGender.bind(this);
    this.handleChangeCountry = this.handleChangeCountry.bind(this);
    this.getCountryCode = this.getCountryCode.bind(this);
    this.handleCountryCodeApiResponse = this.handleCountryCodeApiResponse.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.handleClickChooseFile = this.handleClickChooseFile.bind(this);
    this.handleClickDelete = this.handleClickDelete.bind(this);
    this.handleUpdateUserProfileResponse = this.handleUpdateUserProfileResponse.bind(this);
    this.handleDeleteUserProfileResponse = this.handleDeleteUserProfileResponse.bind(this);
    this.emailValidation = this.emailValidation.bind(this);

    this.state = {
      countryData: [],
      statesName:[],
      loadingScreen: false,
      formValue: {
        fullName: "",
        email: "",
        teamName: "",
        dateOfBirth: "",
        country: "",
        gender: '',
        state:"",
        currentPassword:"",
        newPassword:"",
        confirmPassword:"",
      },
      errorMessageOn: {
        dateOfBirth: "",
        country: "",
        fullName: '',
        teamName: '',
        gender: '',
        email: '',
        state:"",
        currentPassword:"",
        newPassword:"",
        confirmPassword:"",
      },
      isMaleBtnChecked: false,
      isFemaleBtnChecked: false,
      isOtherGenderBtnChecked: false,
      token: '',
      userAvatar: '',
      selectedFile: null,
      onEdit: false,
      toastOpen: false,
      toastMessage: '',
      toastSuccess: true,
      deleteAccountModal: false,
      userId: null,
      userRole: "",
      user_name:"",
      account_type: "",
      sport_position: "",
      sport_title: "",
      esport_title: "",
      in_game_role_name: "",
      userRoleId:null,
      tab:"profile",
      isInvitedUser:false,
      isProfileUpdated: null,
      enableCurrentPassword: false,
      enableNewPassword: false,
      enableConfirmPassword: false,
      subscriptionDueDate:{
        validUntil:""
      },
      chartData: [
        { id: "0", label: "Jan", usage: 0, initialUsage: 0 },
        { id: "1", label: "Feb", usage: 0, initialUsage: 0 },
        { id: "2", label: "Mar", usage: 0, initialUsage: 0 },
        { id: "3", label: "Apr", usage: 0, initialUsage: 0 },
        { id: "4", label: "May", usage: 0, initialUsage: 0 },
        { id: "5", label: "Jun", usage: 0, initialUsage: 0 },
        { id: "6", label: "Jul", usage: 0, initialUsage: 0 },
        { id: "7", label: "Aug", usage: 0, initialUsage: 0 },
        { id: "8", label: "Sep", usage: 0, initialUsage: 0 },
        { id: "9", label: "Oct", usage: 0, initialUsage: 0 },
        { id: "10", label: "Nov", usage: 0, initialUsage: 0 },
        { id: "11", label: "Dec", usage: 0, initialUsage: 0 },
      ],
      initialDateOpen: new Date(new Date().getFullYear() - 13, new Date().getMonth(), new Date().getDate()).toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      }),
      storageUsedPercentage: 0,
      totalStorage:0
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on receive=>>" + JSON.stringify(message));

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      const apiRequestCallIds = {
        [this.getCountryCodeApiCallId]: this.handleCountryCodeApiResponse,
        [this.getUserProfileCallId]: this.handleUserProfileApiResponse,
        [this.updateUserProfileCallId]: this.handleUpdateUserProfileResponse,
        [this.deleteProfileCallId]: this.handleDeleteUserProfileResponse,
        [this.getStatesApiCallId]: this.handleStateCodeApiResponse,
        [this.changePasswordApiCallId]: this.handleChangePasswordResponse,
        [this.getDueDateCallId]: this.handleDueDateResponse,
        [this.getTotalStorageCallId]: this.handleGetTotalStorageResponse,
        [this.getStorageByMonthCallId]: this.handleGetStorageByMonthResponse
      };

      if (apiRequestCallId != null && apiRequestCallIds[apiRequestCallId]) {
        apiRequestCallIds[apiRequestCallId](responseJson, errorResponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    const token = window.localStorage.getItem("authToken") || "";

    this.setState({ token })
    this.getSubscriptionData()
    this.getCountryCode();
    this.getUserProfileData(token);
    this.getStorageByMonth(token);
    this.getTotalStorage(token);
  }

  handleGetTotalStorageResponse = (responseJson: GetTotalStorageResponse, errorResponse?: string) => {
    if (!errorResponse && responseJson && !responseJson.errors) {
      const { team_storage } = responseJson;

      if (team_storage) {
        const used = team_storage.used || 0;
        const total = team_storage.total || 1;

        this.setState({storageUsedPercentage: Number((used / total * 100).toFixed(2)),totalStorage:team_storage.total})      }
    }
  }

  handleGetStorageByMonthResponse = (responseJson: GetStorageByMonthResponse, errorResponse?: string) => {
    if (!errorResponse && responseJson && !responseJson.errors) {
      const { team_usage_per_month } = responseJson;

      if (team_usage_per_month) {
        // map usage data to percentage of 100 for chart
        const normalizeChartData = (chartData: Array<ChartData>): Array<ChartData> => {
          const maxUsage = Math.max(...chartData.map(month => month.usage));
        
          if (maxUsage === 0) return chartData;
        
          const normalizedChartData = chartData.map(month => ({
            ...month,
            initialUsage: month.usage,
            usage: (month.usage / maxUsage) * 100
          }));
        
          return normalizedChartData;
        };

        const updatedChartData = this.state.chartData.map((month) => {
          const usageKey = Object.keys(team_usage_per_month).find((key) =>
            key.startsWith(month.label)
          );
      
          if (usageKey) {
            const usageValue = parseFloat(team_usage_per_month[usageKey].replace("GB", ""));
            return {
              ...month,
              usage: usageValue
            };
          }
      
          return month;
        });
      
        this.setState({ chartData: normalizeChartData(updatedChartData) });
      }
    }
  }

  handleDueDateResponse=(responseJson: any,errorResponse?: string)=>{
    if (!errorResponse && responseJson.valid_until && !responseJson.error) {
      this.setState({subscriptionDueDate:{validUntil:responseJson.valid_until}})
    }
  }

  async handleDeleteUserProfileResponse(responseJson: { message: string, errors?: string }, errorResponse?: string) {
    if (!errorResponse && responseJson && !responseJson.errors) {
      await removeStorageData("authToken");
      await removeStorageData("permissions")
      sessionStorage.clear()
      const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), "LoginBlock");
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);

    }
  }

  handleChangePasswordResponse=(responseJson: any,errorResponse?: string)=>{    
    if (!errorResponse && responseJson && !responseJson.errors) {
      toast.success(responseJson.message)
      this.setState(
        {
          formValue: {
            ...this.state.formValue,
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
          },
        },
      );
    }
    else if(responseJson && responseJson.errors){
      toast.error(responseJson.errors)
    }
  }

  handleStateCodeApiResponse = (responseJson: any, errorResponse?: string) => {
    if (!errorResponse && responseJson) {
      if (!responseJson.error) {
        this.setState({ statesName: responseJson });
      }
    }
  };

  handleUpdateUserProfileResponse(responseJson: UserProfileData, errorResponse?: string) {
    if (responseJson?.data?.id) {
      this.setState({ toastMessage: 'Changes successfully done', onEdit: false, isProfileUpdated:true});
      toast.success("Changes successfully done", {
        position: toast.POSITION.TOP_CENTER,
      });

    } else if (responseJson.errors) {
      this.setState({ toastMessage: responseJson.errors[0], toastSuccess: false });
      toast.success(responseJson.errors[0], {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      this.setState({toastMessage: "Changes failed", toastSuccess: false });
      toast.success( "Changes failed", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    this.getUserProfileData(this.state.token);
  }

  handleUserProfileApiResponse(responseJson: UserProfileData, errorResponse?: string) {
    if (!errorResponse && responseJson) {
      if (!responseJson.error) {
        const userData = responseJson.data.attributes        
        sessionStorage.setItem("profile",JSON.stringify(responseJson?.data?.attributes))
        this.setState(prevState => ({
          ...prevState,
          formValue: {
            ...prevState.formValue,
            fullName: userData.full_name || "",
            email: userData.email || "",
            teamName: userData.team_name || "",
            dateOfBirth: userData.date_of_birth || "",
            country: userData.country || "",
            state: userData.state||"",
          },
          userAvatar: userData.photo,
          userId: userData.account_id,
          userRole: userData.role,
          userRoleId:userData.role_id,
          onEdit: false,
          loadingScreen:false,
          user_name:userData.user_name,
          account_type: userData.account_type,
          sport_position: userData.sport_position,
          sport_title: userData.sport_title,
          esport_title: userData.esport_title,
          in_game_role_name: userData.in_game_role_name,
          isInvitedUser: userData.invited_user,
        }))

        if (userData.gender === "male") {
          this.setState((prevState) => ({
            isMaleBtnChecked: true,
            isFemaleBtnChecked: false,
            formValue: {
              ...prevState.formValue,
              gender: "male",
            },
          }))
        } else if (userData.gender === "female") {
          this.setState((prevState) => ({
            isMaleBtnChecked: false,
            isFemaleBtnChecked: true,
            formValue: {
              ...prevState.formValue,
              gender: "female",
            },
          }))
        } else {
          this.setState((prevState) => ({
            isMaleBtnChecked: false,
            isFemaleBtnChecked: false,
            formValue: {
              ...prevState.formValue,
              gender: userData.gender,
            },
          }))
        }
      }
    }
  }

  getDateRange = () => {
    const today = new Date();
    const minDate = new Date(today.getFullYear() - 120, 0, 1);
    const maxDate = new Date(today.getFullYear() - 13, today.getMonth(), today.getDate());

    return {
      minDate,
      maxDate,
    };
  };

  formValidation() {
    const { formValue } = this.state;

    this.setState({
      errorMessageOn: {
        dateOfBirth: formValue.dateOfBirth.trim() === "" ? "Date of birth must be filled" : "",
        country: formValue.country.trim() === "" ? "Country must be selected" : "",
        state: formValue.state.trim() === "" ? "State must be selected" : "",
        fullName: formValue.fullName.trim() === "" ? "Full name must be filled" : "",
        teamName: formValue.teamName.trim() === "" ? "Team name must be filled" : "",
        gender: formValue.gender.trim() === "" ? "Gender must be filled" : "",
        email: this.emailValidation(formValue.email),
        currentPassword:"",
        newPassword:"",
        confirmPassword:"",
      }
    })

    return ([formValue.dateOfBirth, formValue.country, formValue.fullName, formValue.teamName, formValue.gender,formValue.state].every(value => value.trim() !== '')) && this.emailValidation(formValue.email) === ""
  }

  changePasswordValidation=()=>{
    const { currentPassword, newPassword, confirmPassword } = this.state.formValue;

  const elements = [
    {
      name: "currentPassword",
      validate: currentPassword.trim() === "" ? "Old password must be filled" : "",
    },
    {
      name: "newPassword",
      validate: this.passwordValidation(newPassword.trim()),
    },
    {
      name: "confirmPassword",
      validate: this.confirmPassValidation(confirmPassword.trim(), newPassword.trim()),
    },
  ];

  const errorMessageOn = elements.reduce((accumulator: { [key: string]: string }, current) => {
    accumulator[current.name] = current.validate;
    return accumulator;
  }, {});

  this.setState((prevState) => ({
    errorMessageOn: {
      ...prevState.errorMessageOn,
      ...errorMessageOn,
    },
  }));

  const isValid = Object.values(errorMessageOn).every(
    (value) => (value || "").trim() === ""
  );
  return isValid;

  }

  getTotalStorage = (token: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.getTotalStorageCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTotalStorageUsedURL
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getStorageByMonth = (token: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.getStorageByMonthCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStorageUsedByMonthURL
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleChangePassword= async ()=>{
    const {formValue}= this.state
    const token = await getStorageData("authToken")
    if(this.changePasswordValidation()){
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token
      };

    const dataBody = {
     "new_password":formValue.newPassword,
     "confirm_password":formValue.confirmPassword,
     "old_password":formValue.currentPassword
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.changePasswordApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_forgot_password/passwords/reset_password"
      );
      requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
      );
      requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(dataBody)
      );
      requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
}


  handleEditProfile() {
    let code = this.getKeyByValue(this.state.formValue.country) as string;
    if (!this.state.onEdit) {
      this.setState({ onEdit: true,isProfileUpdated:false })
      this.getStatesNameBasedOnCountryCode(code);
    } else if (this.formValidation()) {
      const formData = new FormData();
      formData.append("profile[country]", code)
      formData.append("profile[full_name]", this.state.formValue.fullName)
      formData.append("profile[gender]", this.state.formValue.gender)
      formData.append("profile[date_of_birth]", this.state.formValue.dateOfBirth)
      formData.append("profile[team_name]", this.state.formValue.teamName)
      formData.append("profile[email]", this.state.formValue.email)
      formData.append("profile[state]",this.state.formValue.state)
      formData.append("account[role_id]",String(this.state.userRoleId))

      const header = {
        token: this.state.token
      }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      )

      this.updateUserProfileCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "/bx_block_profile/profiles/update_profile"
      )
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      )
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      )
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PATCH"
      )
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  changeHandler(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    const { formValue } = this.state;
    this.setState({
      formValue: {
        ...formValue,
        [name]: value,
      },
    });
    this.setState((prevState: S) => ({
      errorMessageOn: {
        ...prevState.errorMessageOn,
        [name]: "",
      },
    }));

    const elements = [
      { name: "fullName", validate: this.fullNameValidation(value) },
      { name: 'teamName', validate: this.teamNameValidation(value) },
      { name: "email", validate: this.emailValidation(value) },
      { name: "currentPassword",validate: this.currentPasswordValidation(value)},
      { name: "newPassword",validate: this.passwordValidation(value)},
      { name: "confirmPassword",validate: this.confirmPassValidation(value,formValue.newPassword)},
    ];
    elements.forEach((item) => {
      if (name === item.name) {
        return this.setState({
          errorMessageOn: {
            ...this.state.errorMessageOn,
            [item.name]: item.validate,
          },
        });
      }
    });
  };
  currentPasswordValidation=(value:string)=>{
    if(!value){
      return "Old Password must be filled";
    }
    else {
      return "";
    }
  }

  confirmPassValidation=(value: string, passValue: string)=> {
    if (!value) {
      return "Confirm Password must be filled";
    } else if (passValue && value && passValue !== value) {
      return "New Password And Confirm Password Must be same";
    } else if (!passValue) {
      return this.passwordValidation(value)
    }
    else {
      return "";
    }
  }
  
  emailValidation(value: string) {
    const emailReg = /^[0-9a-z]+(?:\.[0-9a-z]+)*@[a-z0-9]{2,}(?:\.[a-z]{2,})+$/;

    if (!value) {
      return `Email must be filled`
    } else if (!value.match(emailReg)) {
      return `Invalid email address`
    }
    return ""
  }

  fullNameValidation(value: string) {
    if (!value) {
      return "This Field can't be blank";
    } else {
      return "";
    }
  };


  teamNameValidation(value: string) {
    if (!value) {
      return "This field can't be blank"
    } else {
      return ""
    }
  }

  handleDateInputField(value: DateObject) {
    const date = new Date(value.toDate());
    const formattedDate = date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });

    this.setState((prevState: S) => ({
      formValue: {
        ...prevState.formValue,
        dateOfBirth: formattedDate,
      },
      errorMessageOn: {
        ...prevState.errorMessageOn,
        dateOfBirth: "",
      },
      initialDateOpen: formattedDate
    }));
  };

  handleGender(value: string) {

    // If gender is already selected and neither male nor female button is checked, return

    let updatedGender: string;
    if (value === "male" || value === "female") {
      updatedGender = value;
    } else {
      updatedGender = "other"; // Assuming "other" represents another gender option
    }

    this.setState(
      (prevState) => ({
        isMaleBtnChecked: updatedGender === "male",
        isFemaleBtnChecked: updatedGender === "female",
        formValue: {
          ...prevState.formValue,
          gender: updatedGender,
        },
        errorMessageOn: {
          ...prevState.errorMessageOn,
          gender: "",
        },
      })
    );
  }

  handleChangeGender = (event: { target: { value: string } }) => {
    this.setState((prevState) => ({
      isMaleBtnChecked: false,
      isFemaleBtnChecked: false,
      formValue: {
        ...prevState.formValue,
        gender: event.target.value,
      },
    }));
  };

  handleChangeCountry(selectedOption: { value: string; label: string }) {
    this.setState((prevState: S) => ({
      formValue: {
        ...prevState.formValue,
        country: selectedOption.value,
        state:"",
      },
      statesName:[],
      errorMessageOn: {
        ...prevState.errorMessageOn,
        country: "",
      },
    }));
    let code: string|null = this.getKeyByValue(selectedOption.value);
    this.getStatesNameBasedOnCountryCode(code);
  };

  handleChangeState = (selectedOption: { value: string; label: string }) => {
    this.setState((prevState) => ({
      formValue: {
        ...prevState.formValue,
        state: selectedOption.value,
      },
      errorMessageOn: {
        ...prevState.errorMessageOn,
        state: "",
      },
    }));
  };

  getStatesNameBasedOnCountryCode = async (code: string|null) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getStatesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getStatesApiEndPoint}?country=${code}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getUserProfileData = (token: string) => {
    this.setState({loadingScreen:true})
    const header = {
      "Content-Type": "application/json",
      token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.getUserProfileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_profile/profiles/show"
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    )

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCountryCode() {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCountryCodeApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/account_block/accounts/countries"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleCountryCodeApiResponse(
    responseJson: { error?: string, countryName: Array<{ name: string; code: string }> },
    errorResponse?: string
  ) {
    if (!errorResponse && responseJson) {
      if (!responseJson.error) {
        this.setState({ countryData: responseJson.countryName });
      }
    }
  };

  handleFileChange(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files.length) {
      if (!event.target.files[0].type.startsWith('image/')) {
        return this.setState({ toastOpen: true, toastMessage: 'Invalid file format', toastSuccess: false })
      }

      this.setState({
        selectedFile: event.target.files[0]
      })

      this.handleUpload(event.target.files[0]);
    }
  }

  handleUpload(selectedFile: File) {
    this.setState({isProfileUpdated:false })
    const formData = new FormData();

    formData.append("profile[photo]", selectedFile);
    formData.append("account[role_id]",String(this.state.userRoleId))

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    const header = {
      token: this.state.token
    }

    this.updateUserProfileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_profile/profiles/update_profile"
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleClickChooseFile() {
    this.fileInputRef.current?.click();
  }

  handleClickDelete() {

    const formData = new FormData();

    formData.append("account_id", `${this.state.userId}`);

    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    const header = {
      token: this.state.token
    }

    this.deleteProfileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/account_block/accounts/delete_account"
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  hideToast = () => {
    this.setState({ toastOpen: false, toastMessage: '' })
  }

  passwordValidation(value: string) {
    const minEightChars = /.{8,}/; // At least 8 characters
    const upperCase = /[A-Z]/; // At least one uppercase letter
    const lowerCase = /[a-z]/; // At least one lowercase letter
    const specialChar = /[^a-zA-Z0-9]/; // At least one special character
    const number = /\d/; // At least one digit

    if (!value) {
      return "Password must be filled"; // Error message for blank field
    }
    if (!upperCase.test(value)) {
      return "Password must contain at least one uppercase letter."; // Error message for uppercase letter
    }
    if (!lowerCase.test(value)) {
      return "Password must contain at least one lowercase letter."; // Error message for lowercase letter
    }
    if (!specialChar.test(value)) {
      return "Password must contain at least one special character."; // Error message for special character
    }
    if (!number.test(value)) {
      return "Password must contain at least 1 number."; // Error message for minimum number
    }
    if (!minEightChars.test(value)) {
      return "Password must contain at least 8 characters."; // Error message for minimum length
    }
    return "";
  }

  closeDeleteAccount=()=>{
    this.setState({
      deleteAccountModal:false
    })
  }

  onDeleteAccount=()=>{
    this.setState({
      deleteAccountModal:true
    })
  }
  getKeyByValue = (value: string) => {
    const country = this.state.countryData.find(
      (country) => country.name === value
    );
    return country ? country.code : null;
  };
  onTabChange= (event: React.ChangeEvent<{}>, tab: "profile" | "edit" | "password") =>{
    this.setState({
      tab
    })
  }

  goToUserSubscriptions=()=>{
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Customisableusersubscriptions");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleClickShowCurrentPassword=()=>{
    this.setState((prevState) => ({
      enableCurrentPassword: !prevState.enableCurrentPassword
    }));
  }
  handleClickShowNewPassword=()=>{
    this.setState((prevState) => ({
      enableNewPassword: !prevState.enableNewPassword
    }));
  }
  handleClickShowConfirmPassword=()=>{
    this.setState((prevState) => ({
      enableConfirmPassword: !prevState.enableConfirmPassword
    }));
  }

  getSubscriptionData=async ()=> {
    const token = await getStorageData("authToken");

    const webHeader = {
      "Content-Type": "application/json",
      token
    }
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDueDateCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDueDateEndpoint
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }
  getPassordValMsg = () =>{
    return this.state.errorMessageOn.newPassword && `New ${this.state.errorMessageOn.newPassword}`
  }
  // Customizable Area End
}
