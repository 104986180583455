export const games:any = [
    {
      id: 1,
      gameName: "Game One",
      competition: "Competition A",
      gameLength: "90 minutes",
      gameDate: "2023-10-01"
    },
    {
      id: 2,
      gameName: "Game Two",
      competition: "Competition B",
      gameLength: "120 minutes",
      gameDate: "2023-10-05"
    },
    {
      id: 3,
      gameName: "Game Three",
      competition: "Competition C",
      gameLength: "45 minutes",
      gameDate: "2023-10-10"
    }
  ];
  