import React from "react";

import AnalyticsController, {
  Props,
  configJSON
} from "./AnalyticsController.web";
// Customizable Area Start
import { Box, Button, Grid, InputBase, IconButton } from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import CommonTable from "../../../components/src/CommonTable";
import { Pagination } from "@material-ui/lab";
import { endUserReportSVG, filter, filterOnHover, search } from "./assets";
// Customizable Area End

export default class Analytics extends AnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { currentPage, totalPages } = this.state;

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <NavigationMenu navigation={this.props.navigation} id="NavigationMenu" currentTabProp="stats" />
        <StyledGridItem> 
        <Box>
          <Grid  className="container">
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="grid2">
              <Box className="inputbase">
                <InputBase
                  data-test-id="searchTestId"
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => this.handleSearchName(e.target.value)}
                  className="gridItem2"
                  placeholder="Search By Name..."
                  inputProps={{ 'aria-label': 'Search By Name...' }}

                />
                <Box>
                  <IconButton data-test-id="searchIconTest" type="button" style={{ padding: '10px' }} aria-label="search" onClick={this.handleOnSearch}>
                    <img src={search} className="icons" />
                  </IconButton>
                </Box>
              </Box>
              <Box display={'flex'} gridColumnGap={24}>

              <Box>
                  <Button style={{ border: '1px solid #FF881A' }} data-test-id="filter-btn" className="gridItem2">
                    Compare comming soon...
                    <img src={endUserReportSVG} height={32} width={32} className="img icons default" />
                  </Button>
                </Box>
                <Box>
                  <Button style={{ border: '1px solid #FF881A' }} data-test-id="filter-btn" className="gridItem2 button-hover">
                    Filter
                    <img src={filter} height={32} width={32} className="img icons default" />
                    <img src={filterOnHover} height={32} width={32} className="img icons on-hover" />
                  </Button>
                </Box>
               
              </Box>
            </Grid>
          </Grid>
          <Box style={{ padding: "8px 65px 0px 65px" }}>

          <CommonTable data-test-id='custom-table-id' columns={this.getColumns()} data={this.getUserData()} />
        </Box>
        <StyledPagination
          data-test-id={"user-table-pagination"}
          count={totalPages}
          page={currentPage}
          onChange={this.handleChangePage}
        />
        </Box>

        </StyledGridItem>
        
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    }
  },
  typography: {
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: "20px 0px"
    }
  }
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff"
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)"
  }
};

export const StyledGridItem = styled('div')(({ theme }) => ({
  "& .button-hover .on-hover": {
    display: 'none',
  },

  "& .card": {
    border: "1px solid #FF881A",
    padding: 16,
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    gap: 32,
    width: "100%",
    maxWidth: "94.75rem",
    backgroundColor: '#252527',
    boxSizing: 'border-box'
  },
  "& .details": {
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  },
  "& .divider": {
    height: 100,
    width: 3,
    color: "rgb(255, 136, 26)",
    backgroundColor: "rgb(255, 136, 26)",
    margin: "0px 16px 0px 16px",
    opacity: "50%"
  },

  "& .key": {
    //styleName: text-base/font-normal;
    fontFamily: "Poppins",
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "left",
    color: "#676767 !important",

  },
  "& .value": {
    ontFamily: "Poppins",
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "right",
    color: "#CFD1D4 !important",
  },
  "& .label1": {
    fontSize: 16,
    fontWeight: 500,
    color: "#CFD1D4",
    fontFamily: "Poppins",
    lineHeight: "24px",
  },
  "& .button-hover:hover": {
    backgroundColor: '#FF881A',
    color: "#1D1D1F",

    "& .on-hover": {
      display: 'block',
    },

    "& .default": {
      display: 'none'
    }
  },

  '& .container': {
    width: "93vw",
    margin: "auto",
    marginTop: 20,
    marginBottom: 40,
  },
  '& .grid': {

  },
  '& .grid2': {
    display: "flex",
    gap: 24,
    justifyContent:'space-between'
  },
  '& .gridItem1': {
    display: "flex",
    border: '1px solid #FF881A',
    flex: 1,
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 30px",
    paddingLeft: 20,
    borderRadius: 10,
    fontSize: 20,
    width: "34.4rem",
    color: "#CFD1D4",
    backgroundColor: "252527",
    [theme.breakpoints.down('lg')]: {
      fontSize: 18,
      width: "59%",
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      width: "49%",
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      width: "39%",
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
      width: "29%",
    },
  },
  '& .gridItem2': {
    borderRadius: 10,
    fontSize: 18,
    padding: 18,
    width: '100%',
    color: "#CFD1D4",
    backgroundColor: "252527",
    textTransform: "none",
    [theme.breakpoints.down('lg')]: {
      fontSize: 16,
      padding: 14,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      padding: 12,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      padding: 10,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
      padding: 8,
    },
  },
  '& .inputbase': {
    width: "30%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 10,
    fontSize: 20,
    color: "#CFD1D4",
    backgroundColor: "252527",
    textTransform: "none",
    [theme.breakpoints.down('lg')]: {
      fontSize: 18,
      // width:"50%",
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
      // width:"40%",
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      // width:"30%",
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
      // width:"20%",
    },
  },
  '& .img': {
    marginLeft: 10,
  },
  '& .icons': {
    [theme.breakpoints.down('lg')]: {
      width: 40,
    },
    [theme.breakpoints.down('md')]: {
      width: 30,
    },
    [theme.breakpoints.down('sm')]: {
      width: 20,
    },
    [theme.breakpoints.down('xs')]: {
      width: 10,
    },
  }
}));
export const StyledPagination = styled(Pagination)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  backgroundColor: 'transparent',
  fontSize: 20,
  margin: "50px 0px",
  '& .MuiPaginationItem-root': {
    fontSize: 20,
    color: "#CFD1D4",
  },
  '& .Mui-selected': {
    color: "#E87C18",
  },
  '& .MuiPaginationItem-page.Mui-selected:hover': {
    backgroundColor: 'transparent',
  }
}));
// Customizable Area End
